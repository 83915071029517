<template>
	<div>
		<navbar :subpage="true" :requestedProductId="requestedProductId" />
		<header class="reports-masthead"></header>
		<ol v-if="product" class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Reports' }" exact>Reports</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>{{ product.label }}</span>
			</li>
		</ol>
		<section class="reports" id="reports">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<router-link :to="{ name: 'Reports', query: { page: 1 } }" :class="{ btn: true, 'btn-primary': true }" exact><i class="fas fa-list"></i> Show Latest Reports</router-link>
					</div>
				</div>
			</div>
			<div class="container content-row">
				<div v-if="product" :class="{ row: true, 'mb-1': product && (product.related_subscriptions.length || product.related_products.length) }">
					<div class="col-md-4 report-sample">
						<template v-if="product.document_cover_filename || product.document_sample_page_images.length">
							<div>
								<img v-if="product.document_cover_filename" class="img-fluid" v-lazy="'/files/products/covers/' + product.id + '/' + product.document_cover_filename" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
								<img v-else-if="product.document_sample_page_images.length" class="img-fluid" v-lazy="'/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
								<div v-if="!product.published" :class="{ 'unpublished-content': true, 'has-video': product.youtube_video_id }">
									<span :style="{ top: 'calc(50% - ' + (product.coming_soon_text ? '42' : '21') + 'px)' }"><i class="fa fa-clock"></i> COMING SOON<template v-if="product.coming_soon_text"><br /><small>{{ product.coming_soon_text }}</small></template></span>
									<a v-if="product.youtube_video_id" href="#" @click.prevent="$modal.show('reportVideoModal', { header: product.product_type.name, subheader: product.attributes, videoId: product.youtube_video_id })">
										<div class="play-btn">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.69 311.69"><path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z"></path><polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84"></polygon></svg>
										</div>
									</a>
								</div>
								<div v-if="product.published && product.youtube_video_id" class="has-video">
									<a href="#" @click.prevent="$modal.show('reportVideoModal', { header: product.product_type.name, subheader: product.attributes, videoId: product.youtube_video_id })">
										<div class="play-btn">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.69 311.69"><path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z"></path><polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84"></polygon></svg>
										</div>
									</a>
								</div>
							</div>
							<div class="mt-3">
								<div class="float-left mt-1 mr-1 mb-3">
									<a v-if="product.document_brochure_filename" target="_blank" :href="'/files/products/brochures/' + product.id + '/' + product.document_brochure_filename" class="btn btn-sm btn-primary download-link">Brochure</a>
								</div>
								<div class="float-left mt-1 mr-1 mb-3">
									<a v-if="product.product_skus.length" href="#" @click.prevent="showRequestTocModal(product.id)" class="btn btn-sm btn-primary">Request a Sample</a>
									<a v-if="product.product_type.plans.length" href="#" @click.prevent="showRequestSampleModal(product.product_type.id)" class="btn btn-sm btn-primary">Request a Sample</a>
								</div>
								<div class="float-left mt-1 mb-3">
									<button v-if="product.published" class="btn btn-sm btn-purchase" @click="requestProduct(product)" v-scroll-to="'#register'" type="button">{{ isPaid ? 'Purchase' : 'Download' }}</button>
									<button v-else class="btn btn-sm btn-purchase" @click="requestUnpublishedProduct(product)" v-scroll-to="'#register'" type="button">Notify Me</button>
								</div>
							</div>
						</template>
					</div>
					<div class="col-md-8 report-info">
						<h3>{{ product.product_type.name }}</h3>
						<h1>{{ product.attributes }}</h1>
						<b-tabs v-model="activeTab" ref="tabs">
							<b-tab v-if="product.whats_new_summary" title="What's New" :active="!!product.whats_new_summary">
								<div v-html="product.whats_new_summary" />
							</b-tab>
							<b-tab v-if="product.description" title="Description" :active="!product.whats_new_summary && !!product.description">
								<div v-html="product.description" />
							</b-tab>
							<b-tab v-if="product.contents_summary" title="Contents">
								<div v-html="product.contents_summary" />
							</b-tab>
							<b-tab v-if="product.methodology_summary" title="Methodology">
								<div v-html="product.methodology_summary" />
							</b-tab>
							<b-tab title="Pricing" :active="!product.whats_new_summary && !product.description">
								<div v-if="product.product_skus.length">
									<p v-if="product.product_skus.length > 1">{{ product.product_skus.length }} licensing types are available for this report:</p>
									<p v-else>{{ product.product_skus.length }} licensing type is available for this report:</p>
									<ul>
										<li v-if="singleUserPrices.length">The price of the <strong>single-user license</strong> is {{ singleUserPrices[0].price }}.</li>
										<li v-if="enterprisePrices.length">The price of the <strong>enterprise license</strong> is {{ enterprisePrices[0].price }}<template v-if="isPaid"> (includes supplemental Excel file with all the tables and figures in the report, and report summary slides)</template>.</li>
									</ul>
									<template v-if="isPaid">
										<p v-if="product.product_skus.length > 1">Both licenses include an electronic PDF. Printed copies may be purchased for an additional $250.</p>
										<p v-else>The license includes an electronic PDF. Printed copies may be purchased for an additional $250.</p>
									</template>
								</div>
								<div v-if="product.product_type.plans.length">
									<p v-if="product.product_type.plans.length > 1">{{ product.product_type.plans.length }} licensing types are available for this subscription:</p>
									<p v-else>{{ product.product_type.plans.length }} licensing type is available for this subscription:</p>
									<ul v-for="singleUserPrice in singleUserPrices">
										<li>The price of the {{ singleUserPrice.interval }} <strong>single-user license</strong> is {{ singleUserPrice.price }}.</li>
									</ul>
									<ul v-for="enterprisePrice in enterprisePrices">
										<li>The price of the {{ enterprisePrice.interval }} <strong>enterprise license</strong> is {{ enterprisePrice.price }}.</li>
									</ul>
								</div>
								<div v-html="product.licensing_summary" />
							</b-tab>
							<b-tab v-if="product.authors.length" lazy :title="product.product_type.author_label + ' Profiles'" class="author-profiles">
								<template v-for="author in product.authors">
									<template v-if="!!author.user_attribute.author_profile">
										<h4>
											<strong v-if="author.user_attribute.title">{{ author.formal_name }}, {{ author.user_attribute.title }}</strong>
											<strong v-else>{{ author.formal_name }}</strong>
										</h4>
										<div v-html="author.user_attribute.author_profile" />
										<authored-reports :authorId="author.uid" :productId="product.id" :cardsPerRowMd="1" :cardsPerRowLg="2" :cardsPerRowXl="3" :componentStyle="{ padding: 0, backgroundColor: 'transparent' }" headerText="Additional Authored Reports" :headerClasses="[]" headerFontSize="28px" labelFontSize="1.15rem" :rowPadding="0" />
									</template>
								</template>
							</b-tab>
						</b-tabs>
						<no-ssr>
							<vue-goodshare-linked-in
								has_icon
							/>
							<vue-goodshare-facebook
								has_icon
							/>
							<vue-goodshare-twitter
								has_icon
							/>
						</no-ssr>
						<a href="#" @click.prevent="$modal.show('shareProductModal', { productId: product.id })" class="button-social email__design__flat mr-auto">
							<i class="icon-email"></i>
						</a>
					</div>
				</div>
				<div :class="{ 'mb-4': product && (product.related_subscriptions.length || product.related_products.length) }" v-else>
					<div class="mx-auto">
						<h4>No report found.</h4>
					</div>
				</div>
				<div v-if="product && (product.related_subscriptions.length || product.related_products.length)" class="row">
					<div class="col">
						<h5>Related Reports</h5>
						<div class="container related-products">
							<div class="row d-flex align-items-start">
								<div class="col-md-3 p-1 bg-white border" v-for="relatedProduct in product.related_products">
									<router-link :to="{ name: 'Report', params: { id: relatedProduct.id, slug: relatedProduct.slug }, hash: '#reports' }" v-b-tooltip.hover="product.product_type.name + ': ' + relatedProduct.attributes" class="d-block">
										<img v-if="relatedProduct.document_original_filename || relatedProduct.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + relatedProduct.id + '/' + (relatedProduct.document_sample_filename ? relatedProduct.document_sample_filename.substr(0, relatedProduct.document_sample_filename.lastIndexOf('.')) : relatedProduct.document_original_filename.substr(0, relatedProduct.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="relatedProduct.cover_img_alt ? relatedProduct.cover_img_alt : (product.product_type.name + ': ' + relatedProduct.attributes)" />
									</router-link>
								</div>
								<div class="col-md-3 p-1 bg-white border" v-for="relatedSubscription in product.related_subscriptions">
									<router-link v-if="relatedSubscription.products.length" :to="{ name: 'Report', params: { id: relatedSubscription.products[0].id, slug: relatedSubscription.products[0].slug }, hash: '#reports' }" v-b-tooltip.hover="relatedSubscription.products[0].product_type.name" class="d-block">
										<img v-if="relatedSubscription.products[0].document_original_filename || relatedSubscription.products[0].document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + relatedSubscription.products[0].id + '/' + (relatedSubscription.products[0].document_sample_filename ? relatedSubscription.products[0].document_sample_filename.substr(0, relatedSubscription.products[0].document_sample_filename.lastIndexOf('.')) : relatedSubscription.products[0].document_original_filename.substr(0, relatedSubscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="relatedSubscription.products[0].cover_img_alt ? relatedSubscription.products[0].cover_img_alt : relatedSubscription.products[0].product_type.name" />
										<div v-if="!relatedSubscription.products[0].published" class="unpublished-content">
											<span><i class="fa fa-clock"></i> COMING SOON</span>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<register :headerText="'Want to ' + (isPaid ? 'Purchase' : 'Download') + ' the Full Report?'" :requestedProductId="requestedProductId" />
		<footer-section />
		<request-sample />
		<request-toc />
		<share-product />
		<report-video />
	</div>
</template>

<script>
import { stripHtml } from 'string-strip-html'
import truncate from 'lodash/truncate'
import NoSSR from 'vue-no-ssr'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import { BTabs, BTab } from 'bootstrap-vue'
import { VBTooltip } from 'bootstrap-vue'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import AuthoredReports from './AuthoredReports'
import RequestSample from './modals/RequestSample'
import RequestToc from './modals/RequestToc'
import ShareProduct from './modals/ShareProduct'
import ReportVideo from './modals/ReportVideo'

export default {
	name: 'Report',
	metaInfo() {
		if (this.product) {
			let title = ''
			if (this.product.meta_title) {
				title = this.product.meta_title.trim()
			} else {
				title = this.product.label
			}
			
			let meta = [
				{
					property: 'og:title',
					content: this.product.label,
					template: content => `${content} | Market Scope`,
					vmid: 'og:title'
				},
				{
					property: 'og:url',
					content: '//www.market-scope.com/pages/reports/' + this.product.id + '/' + this.product.slug,
					vmid: 'og:url'
				}
			]
			if (this.product.meta_description) {
				meta.push({
					name: 'description',
					content: this.product.meta_description,
					vmid: 'description'
				})
				meta.push({
					property: 'og:description',
					content: this.product.meta_description,
					vmid: 'og:description'
				})
			} else if (this.product.description) {
				meta.push({
					name: 'description',
					//content: (stripHtml(this.product.description).result).replace(/\r?\n|\r/g, ' ')
					content: truncate(stripHtml(this.product.description).result, {
						length: 160
					}),
					vmid: 'description'
				})
				meta.push({
					property: 'og:description',
					content: stripHtml(this.product.description).result,
					vmid: 'og:description'
				})
			}
			if (this.product.share_img_filename || this.product.document_sample_page_images.length) {
				if (this.product.share_img_filename) {
					meta.push({
						name: 'image',
						property: 'og:image',
						content: 'https://www.market-scope.com/files/products/shares/' + this.product.id + '/' + this.product.share_img_filename,
						vmid: 'og:image'
					})
				} else {
					meta.push({
						name: 'image',
						property: 'og:image',
						content: 'https://www.market-scope.com/files/products/samples/' + this.product.id + '/' + this.product.document_sample_page_images[0],
						vmid: 'og:image'
					})
				}
			}
			if (this.product.authors.length) {
				meta.push({
					name: 'author',
					content: this.product.author_names,
					vmid: 'author'
				})
			}
			
			return {
				title: title,
				meta: meta,
				link: [
					{
						rel: 'canonical',
						href: 'https://www.market-scope.com/pages/reports/' + this.product.id + '/' + this.product.slug,
						vmid: 'canonical'
					}
				]
			}
		}
		
		return {
			title: 'Comprehensive Report'
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', vm.$metaInfo.title + ' | Market Scope'])
		})
	},
	beforeRouteUpdate(to, from, next) {
		next()
		
		const _paq = window._paq = window._paq || []
		if (this.prevRoute && this.prevRoute.fullPath) {
			_paq.push(['setReferrerUrl', window.location.origin + this.$router.resolve(this.prevRoute.fullPath).href])
		}
		_paq.push(['setCustomUrl', window.location.origin + this.$router.resolve(this.$route.fullPath).href])
		_paq.push(['trackPageView', this.$metaInfo.title + ' | Market Scope'])
	},
	asyncData({ store, route }) {
		return store.dispatch('fetchProduct', route.params.id)
	},
	data() {
		return {
			requestedProductId: null,
			activeTab: -1
		}
	},
	computed: {
		product() {
			if (this.$store.state.product) {
				let product = this.$store.state.product
				
				if (product.id === parseInt(this.$route.params.id, 10)) {
					if (product.slug === this.$route.params.slug) {
						return product
					} else if (product.slug_history !== null && product.slug_history.length) {
						if (product.slug_history.some(slug => {
							return slug == this.$route.params.slug
						})) {
							return product
						}
					}
				}
			}
			
			return null
		},
		singleUserPrices() {
			if (this.product) {
				if (this.product.product_skus.length) {
					let sku = this.product.product_skus.find(productSku => productSku.license_type.type === 'Single User')
					if (sku) {
						if (sku.price) {
							return [
								{
									interval: null,
									price: this.$options.filters.currency(sku.price, '$', 0)
								}
							]
						} else {
							return [
								{
									interval: null,
									price: 'Free'
								}
							]
						}
					}
				} else if (this.product.product_type.plans.length) {
					let plans = this.product.product_type.plans.filter(plan => plan.license_type.type === 'Single User')
					if (plans.length) {
						return plans.map(plan => {
							if (plan.price) {
								return {
									interval: plan.pricing_model.model,
									price: this.$options.filters.currency(plan.price, '$', 0)
								}
							} else {
								return {
									interval: plan.pricing_model.model,
									price: 'Free'
								}
							}
						})
					}
				}
			}
			
			return []
		},
		enterprisePrices() {
			if (this.product) {
				if (this.product.product_skus.length) {
					let sku = this.product.product_skus.find(productSku => productSku.license_type.type === 'Enterprise')
					if (sku) {
						if (sku.price) {
							return [
								{
									interval: null,
									price: this.$options.filters.currency(sku.price, '$', 0)
								}
							]
						} else {
							return [
								{
									interval: null,
									price: 'Free'
								}
							]
						}
					}
				} else if (this.product.product_type.plans.length) {
					let plans = this.product.product_type.plans.filter(plan => plan.license_type.type === 'Enterprise')
					if (plans.length) {
						return plans.map(plan => {
							if (plan.price) {
								return {
									interval: plan.pricing_model.model,
									price: this.$options.filters.currency(plan.price, '$', 0)
								}
							} else {
								return {
									interval: plan.pricing_model.model,
									price: 'Free'
								}
							}
						})
					}
				}
			}
			
			return []
		},
		isPaid() {
			if ((this.singleUserPrices.length && this.singleUserPrices.some(price => price.price !== 'Free')) || (this.enterprisePrices.length && this.enterprisePrices.some(price => price.price !== 'Free'))) {
				return true
			}
			
			return false
		}
	},
	methods: {
		requestProduct(product) {
			this.$ga.event('report purchase button click', 'click', product.label)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Report Purchase Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		requestUnpublishedProduct(product) {
			this.$ga.event('unpublished report request button click', 'click', product.label)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Unpublished Report Request Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		showRequestSampleModal(productTypeId) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Request a Sample" Modal'])
			
			this.$modal.show('requestSampleModal', { productTypeId: productTypeId })
		},
		showRequestTocModal(productId) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Request Sample & Table of Contents" Modal'])
			
			this.$modal.show('requestTocModal', { productId: productId })
		},
	},
	watch: {
		activeTab: function (newVal, oldVal) {
			if (oldVal !== -1 && newVal !== oldVal) {
				if (newVal in this.$refs.tabs.tabs) {
					const tabTitle = this.$refs.tabs.tabs[newVal].title
					
					const _paq = window._paq = window._paq || []
					_paq.push(['trackEvent', 'Reports', 'Tab Changed', tabTitle, this.product.id])
				}
			}
		}
	},
	components: {
		'no-ssr': NoSSR,
		VueGoodshareLinkedIn,
		VueGoodshareFacebook,
		VueGoodshareTwitter,
		BTabs,
		BTab,
		Navbar,
		Register,
		FooterSection,
		AuthoredReports,
		RequestSample,
		RequestToc,
		ShareProduct,
		ReportVideo
	},
	directives: {
		'b-tooltip': VBTooltip
	}
}
</script>

<style lang="scss">
.report-info .tabs {
	.nav-link {
		color: $gray-light;
		
		&.active {
			color: #2fbbcc;
			font-weight: 600;
		}
		
		&:not(.active) {
			border-color: #e9ecef #e9ecef #dee2e6;
			
			&:hover,
			&:focus {
				background-color: #f1f1f1;
			}
		}
	}
	
	.tab-content {
		margin-top: 10px;
		
		.tab-pane {
			color: $gray-light;
			
			p {
				font-size: 16px;
			}
			
			.card {
				margin-bottom: 20px;
				
				h6 {
					color: #212529;
				}
				
				.card-body .row {
					margin-bottom: 0;
				}
			}
		}
	}
}

section.reports .author-profiles p img {
	padding: .25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	max-width: 100%;
	height: auto;
	margin: 0 10px 10px 10px;
}
</style>

<style lang="scss" scoped>
header.reports-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: 40% 60% url('~@assets/img/reports-front-bg.jpg'), $theme-secondary;
	background: 40% 60% url('~@assets/img/reports-front-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.reports {
	background: none;
	
	.section-heading {
		color: #0a5384;
		margin-bottom: 8px;
	}
	
	.row {
		min-height: auto;
	}
	
	.header-row {
		margin-bottom: 50px;
	}
	
	.content-row {
		.row {
			margin-bottom: 30px;
		}
		
		h1,
		h3 {
			color: #0a5384;
		}
	}
	
	p {
		color: $gray-light;
	}
	
	.unpublished-content {
		position: absolute;
		background: rgba(0, 0, 0, .6);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		
		span {
			position: absolute;
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 28px;
			
			.fa {
				color: #fff;
				font-size: 28px;
			}
			
			small {
				font-size: 20px;
			}
		}
	}
	
	.related-products .unpublished-content {
		top: 0.25rem;
		left: 0.25rem;
		width: calc(100% - 0.5rem);
		height: calc(100% - 0.5rem);
		
		span {
			top: calc(50% - 14px);
			font-size: 18px;
			
			.fa {
				font-size: 18px;
			}
		}
	}
	
	.report-sample {
		.btn {
			text-transform: none;
		}
		.btn-purchase {
			color: #fff;
			background-color: #e3a220;
			
			&:hover {
				color: #eee;
			}
		}
		
		> div {
			position: relative;
			box-shadow: -5px 5px 30px 0px rgba(0, 0, 0, 0.3);
			
			&:hover .has-video:not(.unpublished-content),
			&:focus .has-video:not(.unpublished-content) {
				position: absolute;
				background: rgba(255, 255, 255, .3);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
			}
			
			.has-video {
				/* SVG play button container */
				.play-btn {
					position: absolute;
					max-width: 700px;
					width: 100px;
					height: 100px;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.play-btn svg {
					transition: all .2s ease;
					fill: #fff;
					filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
				}
				.play-btn:hover svg,
				.play-btn:focus svg {
					cursor: pointer;
					fill: #2fbbcc;
				}
				/* adjust your icon size, add different breakpoints as needed */
				@media screen and (max-width: 600px) {
					.play-btn {
						width: 70px;
						height: 70px;
					}
				}
			}
		}
		
		.unpublished-content.has-video {
			.play-btn {
				width: 85px !important;
				height: 85px !important;
				top: 45% !important;
			}
			
			span {
				top: calc(65% - 21px) !important;
			}
			/* adjust your icon size, add different breakpoints as needed */
			@media screen and (max-width: 1200px) {
				span {
					top: calc(70% - 21px) !important;
				}
			}
		}
		
		.fa {
			color: #2fbbcc;
			font-size: 32px;
		}
	}
	
	.author-profiles > h4 {
		color: #555;
	}
	
	.report-info > h1 {
		font-size: 28px;
		font-weight: 500;
	}
	
	.report-info > h3 > span {
		border-bottom: 1px solid;
		line-height: 2.2;
	}
	
	.related-products .row > .col-md-3 {
		margin-right: -1px;
		margin-bottom: -1px;
	}
}

.button-social * {
	box-sizing: border-box;
}

// Button Social link style
.button-social {
	display: inline-flex;
	cursor: pointer;
	padding: 7px 10px;
	margin: 3px 1.5px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

// Button Social link style on hover
.button-social:hover {
	opacity: 0.9;
}

a.button-social {
	color: #fff !important;
	
	[class^="icon-"]:before,
	[class*=" icon-"]:before {
		font-family: Fontello;
		font-style: normal;
		font-weight: normal;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: 0.2em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: 0.2em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	.icon-email:before {
		content: "\e80f";
	}
}

a.email__design__flat {
	background-color: rgb(65, 65, 65);
	color: rgb(254, 254, 254);
}
</style>