<template>
	<div>
		<navbar :subpage="true" :requestedProductId="requestedProductId" />
		<header class="market-trends-masthead">
			<h1 class="section-heading">Ophthalmic Market Trends</h1>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Ophthalmic Market Trends</span>
			</li>
		</ol>
		<section class="market-trends intro" id="market-trends">
			<div class="container">
				<div class="row mb-3">
					<div class="col text-center">
						<h3>Delivering concise, unbiased, expert-reviewed analytics every quarter <span>to drive innovation and competitive intelligence.</span> Ideal for companies and investors of all sizes exploring...</h3>
					</div>
				</div>
				<div class="row px-5">
					<div class="col-md-3 text-center">
						<img v-lazy="require('@assets/img/market-trends/Icon-New-Product-Entry.png')" />
						<h6 class="m-4">New Product Entry</h6>
					</div>
					<div class="col-md-3 text-center">
						<img v-lazy="require('@assets/img/market-trends/Icon-Product-Share-Tracking.png')" />
						<h6 class="m-4">Established Product Share Tracking</h6>
					</div>
					<div class="col-md-3 text-center">
						<img v-lazy="require('@assets/img/market-trends/Icon-Provider-Preferences.png')" />
						<h6 class="m-4">Provider Preferences</h6>
					</div>
					<div class="col-md-3 text-center">
						<img v-lazy="require('@assets/img/market-trends/Icon-Market-Fluctuations.png')" />
						<h6 class="m-4">Market Fluctuations</h6>
					</div>
				</div>
			</div>
		</section>
		<section class="market-trends offerings">
			<div class="container">
				<div class="row mb-3">
					<div class="col text-center">
						<h2>Four Specialty Offerings</h2>
						<p>Bonus coverage of ocular surface disease, ocular plastics, and practice management routinely included. Custom data cuts and cross tabulation packages available.</p>
					</div>
				</div>
				<div class="row align-items-center mx-5 mb-5">
					<div class="col-md-4 my-3 text-center">
						<a v-if="selectedSpecialtyIdx > 0" @click.prevent="specialtyPrev" class="specialty-slider-btn">
							<img v-lazy="require('@assets/img/market-trends/Left-Arrow.png')" />
						</a>
					</div>
					<div class="col-md-4 text-center">
						<transition-group tag="div" class="specialty-slider" :name="specialtyBack ? 'slideback' : 'slide'">
							<img v-for="img in specialtyImgs" :key="img" v-if="specialtyImgs[selectedSpecialtyIdx] === img" :src="require('@assets/img/market-trends/' + specialtyImgs[selectedSpecialtyIdx])" class="img-fluid" />
						</transition-group>
					</div>
					<div class="col-md-4 my-3 text-center">
						<a v-if="selectedSpecialtyIdx < specialtyImgs.length - 1" @click.prevent="specialtyNext" class="specialty-slider-btn">
							<img v-lazy="require('@assets/img/market-trends/Right-Arrow.png')" />
						</a>
					</div>
				</div>
				<div class="row">
					<div class="card-deck mx-auto">
						<div v-if="cataractReport" @click="selectedSpecialtyIdx = 0" :class="{ card: true, 'd-flex': true, 'shadow-lg': true, 'border-0': true, 'rounded-0': true, 'mx-2': true, 'mb-5': true, 'offerings-cataract-report': true, selected: selectedSpecialtyIdx === 0 }">
							<div class="card-top-arrow"></div>
							<div class="card-header border-bottom-0 py-5">
								<h3 class="card-title text-center mb-0">Cataract Edition</h3>
							</div>
							<div class="card-body d-flex flex-column px-3">
								<ul>
									<li>Location of cataract surgeries</li>
									<li>Procedure volumes</li>
									<li>US premium IOL penetration and surgeon adoption</li>
									<li>US IOL market shares by surgery locations...</li>
								</ul>
								<div class="text-center mt-auto mt-4 mb-2">
									<router-link @click.native.stop :to="{ name: 'Report', params: { id: cataractReport.id, slug: cataractReport.slug }, hash: '#reports' }" class="btn px-5 py-2">Learn More</router-link>
								</div>
							</div>
						</div>
						<div v-if="refractiveReport" @click="selectedSpecialtyIdx = 1" :class="{ card: true, 'd-flex': true, 'shadow-lg': true, 'border-0': true, 'rounded-0': true, 'mx-2': true, 'mb-5': true, 'offerings-refractive-report': true, selected: selectedSpecialtyIdx === 1 }">
							<div class="card-top-arrow"></div>
							<div class="card-header border-bottom-0 py-5">
								<h3 class="card-title text-center mb-0">Refractive Edition</h3>
							</div>
							<div class="card-body d-flex flex-column px-3">
								<ul>
									<li>Laser center ownership by market segment</li>
									<li>Average refractive surgery fees</li>
									<li>Location of surgery</li>
									<li>Procedure market share by technology/device...</li>
								</ul>
								<div class="text-center mt-auto mt-4 mb-2">
									<router-link @click.native.stop :to="{ name: 'Report', params: { id: refractiveReport.id, slug: refractiveReport.slug }, hash: '#reports' }" class="btn px-5 py-2">Learn More</router-link>
								</div>
							</div>
						</div>
						<div class="w-100 d-none d-sm-block d-md-block d-lg-none"></div>
						<div v-if="glaucomaReport" @click="selectedSpecialtyIdx = 2" :class="{ card: true, 'd-flex': true, 'shadow-lg': true, 'border-0': true, 'rounded-0': true, 'mx-2': true, 'mb-5': true, 'offerings-glaucoma-report': true, selected: selectedSpecialtyIdx === 2 }">
							<div class="card-top-arrow"></div>
							<div class="card-header border-bottom-0 py-5">
								<h3 class="card-title text-center mb-0">Glaucoma Edition</h3>
							</div>
							<div class="card-body d-flex flex-column px-3">
								<ul>
									<li>Primary patient diagnoses</li>
									<li>Number of prescribed medications</li>
									<li>Patient medication usage and compliance</li>
									<li>First and second line therapies...</li>
								</ul>
								<div class="text-center mt-auto mt-4 mb-2">
									<router-link @click.native.stop :to="{ name: 'Report', params: { id: glaucomaReport.id, slug: glaucomaReport.slug }, hash: '#reports' }" class="btn px-5 py-2">Learn More</router-link>
								</div>
							</div>
						</div>
						<div v-if="retinaReport" @click="selectedSpecialtyIdx = 3" :class="{ card: true, 'd-flex': true, 'shadow-lg': true, 'border-0': true, 'rounded-0': true, 'mx-2': true, 'mb-5': true, 'offerings-retina-report': true, selected: selectedSpecialtyIdx === 3 }">
							<div class="card-top-arrow"></div>
							<div class="card-header border-bottom-0 py-5">
								<h3 class="card-title text-center mb-0">Retina Edition</h3>
							</div>
							<div class="card-body d-flex flex-column px-3">
								<ul>
									<li>IVT injections by disease and product</li>
									<li>DME treatments</li>
									<li>Corticosteroid use</li>
									<li>Vitrectomy volume and location</li>
									<li>Use of Eylea, Lucentis, Avastin...</li>
								</ul>
								<div class="text-center mt-auto mt-4 mb-2">
									<router-link @click.native.stop :to="{ name: 'Report', params: { id: retinaReport.id, slug: retinaReport.slug }, hash: '#reports' }" class="btn px-5 py-2">Learn More</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="market-trends deliverables">
			<div class="container">
				<div class="row mt-4 mb-5">
					<div class="col text-center">
						<h2>Ophthalmic Market Trends (OMT) Deliverables</h2>
					</div>
				</div>
				<div class="row">
					<div class="card-deck px-5 mx-auto">
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Deliverables-Trend-Analysis.jpg')" class="card-img-top rounded-0 w-100" alt="Trend Analysis" />
							<div class="card-body text-center">
								<h5 class="card-title">Trend Analysis</h5>
								<p class="mb-0">Trend analysis based on comparisons with information gathered in previous years.</p>
							</div>
						</div>
						<div class="w-100 d-none d-sm-block d-md-block d-lg-none"></div>
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Deliverables-Two-Year-Summary.jpg')" class="card-img-top rounded-0 w-100" alt="Two-Year Summary" />
							<div class="card-body text-center">
								<h5 class="card-title">Two-Year Summary</h5>
								<p class="mb-0">Two-year historical summary by quarter of procedures by type and product used.</p>
							</div>
						</div>
						<div class="w-100 d-none d-sm-block d-md-block d-lg-none"></div>
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Deliverables-Full-Year-Forecast.jpg')" class="card-img-top rounded-0 w-100" alt="Full-Year Forecast" />
							<div class="card-body text-center">
								<h5 class="card-title">Full-Year Forecast</h5>
								<p class="mb-0">A full-year forecast for procedures within each specialty.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="market-trends sources">
			<div class="container">
				<div class="row mt-4 mb-5">
					<div class="col text-center">
						<h2>OMT Sources</h2>
					</div>
				</div>
				<div class="row">
					<div class="card-deck px-5 mx-auto">
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Sources-Surveys.jpg')" class="card-img-top rounded-0 w-100" alt="Surveys of Individual Confirmed Ophthalmologists" />
							<div class="card-body d-flex align-items-center text-center pt-3 pb-4">
								<h5 class="card-title">Surveys of Individual Confirmed Ophthalmologists</h5>
							</div>
						</div>
						<div class="w-100 d-none d-sm-block d-md-block d-lg-none"></div>
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Sources-Disease-Models.jpg')" class="card-img-top rounded-0 w-100" alt="Proprietary Disease Models" />
							<div class="card-body d-flex align-items-center text-center pt-3 pb-4">
								<h5 class="card-title">Proprietary Disease Models</h5>
							</div>
						</div>
						<div class="w-100 d-none d-sm-block d-md-block d-lg-none"></div>
						<div class="card d-flex border-0 rounded-0 shadow-lg mb-5">
							<img v-lazy="require('@assets/img/market-trends/OMT-Sources-Primary-Research.jpg')" class="card-img-top rounded-0 w-100" alt="Other Primary Research and Public Data Sources" />
							<div class="card-body d-flex align-items-center text-center pt-3 pb-4">
								<h5 class="card-title">Other Primary Research and Public Data Sources</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="market-trends highlights">
			<div class="container">
				<div class="row mt-4 mb-5">
					<div class="col text-center">
						<h2>Highlights/Contents of OMT by Specialty</h2>
						<p class="mb-2">Delivering quarterly market share analysis & forecasts, ATUs, and other industry trends.</p>
					</div>
				</div>
				<div v-if="cataractReport" class="row mb-5">
					<div class="card-group px-5 mx-3 w-100">
						<div class="card d-flex border-0 rounded-0 report-overview">
							<div class="card-body pt-5 px-5 pb-4">
								<h5 class="mb-4">US OMT Cataract Report</h5>
								<ul>
									<li>Location of cataract surgeries</li>
									<li>Procedure volumes</li>
									<li>US market shares by model for IOLs: monofocal, presbyopia-correcting-IOLs, and toric IOLs</li>
									<li>US premium IOL penetration and surgeon adoption</li>
									<li>US IOL market shares by surgery locations</li>
									<li>US phaco machines market share and installed base</li>
									<li>US market shares for viscoelastic</li>
									<li>Adoption plans for femtosecond laser cataract surgery (FLACS)</li>
									<li>Adoption plans and use cases for intraoperative and postoperative pharmaceuticals</li>
									<li>Procedure volumes and market share for MIGS stents and canal-based procedures</li>
									<li>Product purchasing plans</li>
									<li>Average premium IOL surgery fees by type</li>
									<li>Patient financing</li>
								</ul>
								<div>
									<button @click="requestProduct(cataractReport)" v-scroll-to="'#register'" type="button" class="btn btn-purchase mr-3 mb-4 py-2 px-3">Purchase</button>
									<a @click.prevent="$modal.show('requestSampleModal', { productTypeId: cataractReport.product_type_id })" class="btn btn-purchase-outline mb-4 py-2 px-3">Request a Sample</a>
								</div>
							</div>
						</div>
						<div class="card d-flex border-0 rounded-0 report-cover">
							<div class="card-body d-flex align-items-center justify-content-center px-5">
								<img :src="cataractReport.cover_img" class="img-fluid shadow" style="max-height: 577px;" />
							</div>
						</div>
					</div>
				</div>
				<div v-if="refractiveReport" class="row mb-5">
					<div class="card-group px-5 mx-3 w-100">
						<div class="card d-flex border-0 rounded-0 report-cover">
							<div class="card-body d-flex align-items-center justify-content-center px-5">
								<img :src="refractiveReport.cover_img" class="img-fluid shadow" style="max-height: 577px;" />
							</div>
						</div>
						<div class="card d-flex border-0 rounded-0 report-overview">
							<div class="card-body pt-5 px-5 pb-4">
								<h5 class="mb-4">US OMT Refractive Report</h5>
								<ul>
									<li>Laser center ownership by market segment</li>
									<li>Average refractive surgery fees by type</li>
									<li>Location of surgery</li>
									<li>Procedure market share by technology/device</li>
									<li>Femto/flap-maker and excimer lasers used by responding surgeons</li>
									<li>Procedure volume by type: LASIK, surface ablation, SMILE, corneal inlays, phakic IOLs</li>
									<li>Refractive error distribution by patient candidate</li>
									<li>Ocular surface disease prevalence/screening and treatment</li>
									<li>Surgeon interest in presbyopia drops</li>
								</ul>
								<div>
									<button @click="requestProduct(refractiveReport)" v-scroll-to="'#register'" type="button" class="btn btn-purchase mr-3 mb-4 py-2 px-3">Purchase</button>
									<a @click.prevent="$modal.show('requestSampleModal', { productTypeId: refractiveReport.product_type_id })" class="btn btn-purchase-outline mb-4 py-2 px-3">Request a Sample</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="glaucomaReport" class="row mb-5">
					<div class="card-group px-5 mx-3 w-100">
						<div class="card d-flex border-0 rounded-0 report-overview">
							<div class="card-body pt-5 px-5 pb-4">
								<h5 class="mb-4">US OMT Glaucoma Report</h5>
								<ul>
									<li>Primary patient diagnoses</li>
									<li>Glaucoma treatment regimens: medication & surgical intervention rates</li>
									<li>Number of prescribed medications</li>
									<li>Patient medication usage and compliance</li>
									<li>First and second line therapies</li>
									<li>Glaucoma procedure volumes and share by type</li>
									<li>MIGS adoption and procedures by product</li>
									<li>Canal-based adoption and procedures by product</li>
									<li>Glaucoma tube shunt procedure by product</li>
									<li>Laser procedures by type</li>
									<li>Interest in investigational glaucoma treatments</li>
									<li>Surgical locations</li>
								</ul>
								<div>
									<button @click="requestProduct(glaucomaReport)" v-scroll-to="'#register'" type="button" class="btn btn-purchase mr-3 mb-4 py-2 px-3">Purchase</button>
									<a @click.prevent="$modal.show('requestSampleModal', { productTypeId: glaucomaReport.product_type_id })" class="btn btn-purchase-outline mb-4 py-2 px-3">Request a Sample</a>
								</div>
							</div>
						</div>
						<div class="card d-flex border-0 rounded-0 report-cover">
							<div class="card-body d-flex align-items-center justify-content-center px-5">
								<img :src="glaucomaReport.cover_img" class="img-fluid shadow" style="max-height: 577px;" />
							</div>
						</div>
					</div>
				</div>
				<div v-if="retinaReport" class="row mb-5">
					<div class="card-group px-5 mx-3 w-100">
						<div class="card d-flex border-0 rounded-0 report-cover">
							<div class="card-body d-flex align-items-center justify-content-center px-5">
								<img :src="retinaReport.cover_img" class="img-fluid shadow" style="max-height: 577px;" />
							</div>
						</div>
						<div class="card d-flex border-0 rounded-0 report-overview">
							<div class="card-body pt-5 px-5 pb-4">
								<h5 class="mb-4">US OMT Retina Report</h5>
								<ul>
									<li>IVT injections by disease and product</li>
									<li>Use of Eylea, Lucentis, Avastin, Macugen, and Visudyne PDT</li>
									<li>DME treatments</li>
									<li>Corticosteroid use</li>
									<li>Photocoagulation volume and photocoagulators used</li>
									<li>Vitrectomy volume and location</li>
									<li>Vitrectomy machine use and market share</li>
									<li>Reusable and disposable instruments</li>
									<li>Visualization agents</li>
								</ul>
								<div>
									<button @click="requestProduct(retinaReport)" v-scroll-to="'#register'" type="button" class="btn btn-purchase mr-3 mb-4 py-2 px-3">Purchase</button>
									<a @click.prevent="$modal.show('requestSampleModal', { productTypeId: retinaReport.product_type_id })" class="btn btn-purchase-outline mb-4 py-2 px-3">Request a Sample</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-5">
					<div class="col text-center">
						<h2>Not seeing what you need?</h2>
						<p class="mb-2">In addition to our syndicated market trends reports, we also leverage our expert knowledge of the ophthalmic industry and robust panel of US and European ophthalmologists and optometrists to design custom primary research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in helping clients with new product design, messaging, pricing and experience with current technology, and expectations from future treatments.</p>
					</div>
				</div>
				<div class="row">
					<div class="card-group px-5 mx-3 w-100">
						<div class="card d-flex bg-transparent border-0 report-overview">
							<div class="card-body d-flex flex-column justify-content-center">
								<h5 class="pt-3 mb-4">Custom Project Types Include:</h5>
								<ul>
									<li>Custom targeted surveys</li>
									<li>Surveying and/or interviewing of ophthalmologists</li>
									<li>Advisory boards</li>
									<li>Territory analyses</li>
									<li>Other primary research initiatives</li>
								</ul>
								<p>Each project is custom designed to meet each specific client's needs. Please contact us for an individualized project consultation.</p>
								<div class="mt-3">
									<button @click="showContactUsModal" type="button" class="btn btn-purchase mr-3 mb-4 py-2 px-3">Contact Us</button>
									<router-link :to="{ name: 'PrimaryResearch' }" class="btn btn-purchase-outline mb-4 py-2 px-3">Primary Research Options</router-link>
								</div>
							</div>
						</div>
						<div class="card d-flex bg-transparent border-0">
							<div class="card-body d-flex align-items-center justify-content-center px-5">
								<img src="/static/landing/img/product-primary-research-cover.jpg" class="img-fluid shadow" style="max-height: 554px;" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="market-trends register">
			<div class="container" id="register">
				<div class="row">
					<div class="col text-center mb-3">
						<h3>Want to Purchase the Full Report?</h3>
						<div class="d-flex justify-content-center mt-3 mb-4">
							<div :class="{ 'position-relative': true, 'mr-3': true, selected: registerType === 'corporate' }">
								<button @click="registerType = 'corporate'" type="button" class="btn btn-register py-2 px-3">I am an Industry Professional</button>
								<div class="btn-bottom-arrow"></div>
							</div>
							<div :class="{ 'position-relative': true, selected: registerType === 'clinical' }">
								<button @click="registerType = 'clinical'" type="button" class="btn btn-register py-2 px-3">I am a Medical Professional</button>
								<div class="btn-bottom-arrow"></div>
							</div>
						</div>
						<p v-if="registerType === 'corporate'">A corporate account gives you access to licensed reports and subscriptions, the latest news, a personalized dashboard, and weekly emails with news and data.</p>
						<p v-if="registerType === 'clinical'">A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.</p>
					</div>
				</div>
				<div class="card shadow-lg mx-5">
					<div class="card-body">
						<form method="post" v-promise-btn="{ action: registerMethod === 'methodRegister' ? 'submit' : 'login' }" @submit.prevent="registerMethod === 'methodRegister' ? submit() : login()" accept-charset="utf-8" novalidate id="registration-form" ref="registrationForm" role="form" :action="registerMethod === 'methodRegister' ? '/users/' + (registerType === 'corporate' ? 'corporate-' : '') + 'register' : '/users/login'">
							<div style="display: none;">
								<input type="hidden" name="_method" value="POST" class="form-control" />
								<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
								<input v-if="registerType === 'corporate'" type="hidden" name="user_attribute[requested_product_id]" :value="requestedProductId" />
								<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
								<input type="hidden" name="origin" value="market_trends" />
								<select v-if="registerType === 'clinical'" multiple name="tag_list[]">
									<option v-for="tag in tag_list" :value="tag" selected>{{ tag }}</option>
								</select>
							</div>
							<div class="text-center my-3">
								<h5 class="card-title">Sign In or Register</h5>
							</div>
							<div class="form-row">
								<div class="form-group col text-center">
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" v-model="registerMethod" value="methodRegister" id="methodRegister" class="custom-control-input" />
										<label class="custom-control-label" for="methodRegister">Register an account</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" v-model="registerMethod" value="methodSignIn" id="methodSignIn" class="custom-control-input" />
										<label class="custom-control-label" for="methodSignIn">Sign in</label>
									</div>
								</div>
							</div>
							<div v-if="registerMethod === 'methodRegister'" class="form-row">
								<div class="form-group col-md-6">
									<label for="inputFullName">Full Name*</label>
									<input type="text" v-model.trim="$v.registerForm.full_name.$model" name="full_name" placeholder="Full Name" id="inputFullName" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.full_name.$dirty && $v.registerForm.full_name.$error }" />
									<div class="invalid-feedback" v-if="$v.registerForm.full_name.$dirty && !$v.registerForm.full_name.required">Full name is required.</div>
									<div class="invalid-feedback" v-if="$v.registerForm.full_name.$dirty && !$v.registerForm.full_name.minLength">Full name has a minimum of 3 characters.</div>
								</div>
								<div v-if="registerType === 'corporate'" class="form-group col-md-3">
									<label for="inputCompany">Company*</label>
									<vue-bootstrap-typeahead
										placeholder="Company Name"
										v-model.trim="$v.companyName.$model"
										:data="companyNames"
										:serializer="v => v.name"
										@hit="selectCompany"
										@input="deselectCompany"
										id="inputCompany"
										:inputClass="$v.companyName.$dirty && $v.companyName.$error ? 'is-invalid' : ''"
									/>
									<input type="hidden" name="companies[0][name]" ref="corporate_company_name" value="" />
									<input type="hidden" name="companies[0][id]" ref="corporate_company_id" value="" />
									<div v-if="$v.companyName.$dirty && !$v.companyName.required" class="invalid-feedback" style="display: block;">Company name is required.</div>
								</div>
								<div v-if="registerType === 'corporate'" class="form-group col-md-3">
									<label for="inputJobTitle">Job Title</label>
									<input type="text" class="form-control" name="user_attribute[title]" id="inputJobTitle" placeholder="Job Title" maxlength="255">
								</div>
								<div v-if="registerType === 'clinical'" class="form-group col-md-6">
									<label for="inputPracticeName">Practice Name*</label>
									<input type="text" v-model.trim="$v.registerForm.user_attribute.practice.name.$model" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice.name.$dirty && $v.registerForm.user_attribute.practice.name.$error }" name="user_attribute[practice][name]" id="inputPracticeName" placeholder="Practice Name" required maxlength="255">
									<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.name.$dirty && !$v.registerForm.user_attribute.practice.name.required">Practice name is required.</div>
									<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.name.$dirty && !$v.registerForm.user_attribute.practice.name.minLength">Practice name has a minimum of 3 characters.</div>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="inputEmail">Email*</label>
									<input type="email" v-model.trim="$v.registerForm.email.$model" :name="registerMethod === 'methodRegister' ? 'email' : 'login_email'" placeholder="Email" id="inputEmail" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.email.$dirty && $v.registerForm.email.$error }" />
									<div class="invalid-feedback" v-if="$v.registerForm.email.$dirty && !$v.registerForm.email.required">Email address is required.</div>
									<div class="invalid-feedback" v-if="$v.registerForm.email.$dirty && !$v.registerForm.email.email">Email address has to be valid.</div>
								</div>
								<div :class="{ 'form-group': true, 'col-md-3': registerMethod === 'methodRegister', 'col-md-6': registerMethod === 'methodSignIn' }">
									<label for="inputPassword">Password*</label>
									<input type="password" v-model.trim="$v.registerForm.passwd.$model" :name="registerMethod === 'methodRegister' ? 'passwd' : 'password'" placeholder="Enter a password" id="inputPassword" required :class="{ 'form-control': true, 'is-invalid': $v.registerForm.passwd.$dirty && $v.registerForm.passwd.$error }" />
									<div class="invalid-feedback" v-if="$v.registerForm.passwd.$dirty && !$v.registerForm.passwd.required">Password is required.</div>
									<div class="invalid-feedback" v-if="$v.registerForm.passwd.$dirty && !$v.registerForm.passwd.minLength">Password has a minimum of 6 characters.</div>
								</div>
								<div v-if="registerMethod === 'methodRegister'" class="form-group col-md-3">
									<label for="inputReenterPassword">Re-enter Password*</label>
									<input type="password" v-model.trim="$v.registerForm.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="inputReenterPassword" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.passwd_confirm.$dirty && $v.registerForm.passwd_confirm.$error }" />
									<div class="invalid-feedback" v-if="$v.registerForm.passwd_confirm.$dirty && !$v.registerForm.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
								</div>
							</div>
							<div v-if="registerMethod === 'methodRegister' && registerType === 'clinical'" class="form-row">
								<div :class="{ 'form-group': true, 'col-md-6': registerForm.user_attribute.practice.location_id === 1, col: registerForm.user_attribute.practice.location_id !== 1 }">
									<label for="inputPracticeLocationId" class="control-label">Country*</label>
									<multiselect
										v-model.trim="selectedCountry"
										:options="countryOptions"
										placeholder="Select your country..."
										label="alias"
										track-by="id"
										deselect-label=""
										aria-invalid="false"
										id="inputPracticeLocationId"
										required
										:class="{ 'is-invalid': $v.registerForm.user_attribute.practice.location_id.$dirty && $v.registerForm.user_attribute.practice.location_id.$error }"
									>
										<span slot="noResult">No matching countries found.</span>
									</multiselect>
									<input type="hidden" name="user_attribute[practice][location_id]" :value="registerForm.user_attribute.practice.location_id" />
									<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.location_id.$dirty && !$v.registerForm.user_attribute.practice.location_id.required">Country is required.</div>
								</div>
								<div v-if="registerForm.user_attribute.practice.location_id === 1" class="form-group col-md-6">
									<label for="inputPracticeState" class="control-label">State</label>
									<select v-model="registerForm.user_attribute.practice.state" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice.state.$dirty && $v.registerForm.user_attribute.practice.state.$error }" name="user_attribute[practice][state]" id="inputPracticeState">
										<option value="AL">Alabama</option>
										<option value="AK">Alaska</option>
										<option value="AZ">Arizona</option>
										<option value="AR">Arkansas</option>
										<option value="CA">California</option>
										<option value="CO">Colorado</option>
										<option value="CT">Connecticut</option>
										<option value="DE">Delaware</option>
										<option value="FL">Florida</option>
										<option value="GA">Georgia</option>
										<option value="HI">Hawaii</option>
										<option value="ID">Idaho</option>
										<option value="IL">Illinois</option>
										<option value="IN">Indiana</option>
										<option value="IA">Iowa</option>
										<option value="KS">Kansas</option>
										<option value="KY">Kentucky</option>
										<option value="LA">Louisiana</option>
										<option value="ME">Maine</option>
										<option value="MD">Maryland</option>
										<option value="MA">Massachusetts</option>
										<option value="MI">Michigan</option>
										<option value="MN">Minnesota</option>
										<option value="MS">Mississippi</option>
										<option value="MO">Missouri</option>
										<option value="MT">Montana</option>
										<option value="NE">Nebraska</option>
										<option value="NV">Nevada</option>
										<option value="NH">New Hampshire</option>
										<option value="NJ">New Jersey</option>
										<option value="NM">New Mexico</option>
										<option value="NY">New York</option>
										<option value="NC">North Carolina</option>
										<option value="ND">North Dakota</option>
										<option value="OH">Ohio</option>
										<option value="OK">Oklahoma</option>
										<option value="OR">Oregon</option>
										<option value="PA">Pennsylvania</option>
										<option value="RI">Rhode Island</option>
										<option value="SC">South Carolina</option>
										<option value="SD">South Dakota</option>
										<option value="TN">Tennessee</option>
										<option value="TX">Texas</option>
										<option value="UT">Utah</option>
										<option value="VT">Vermont</option>
										<option value="VA">Virginia</option>
										<option value="WA">Washington</option>
										<option value="WV">West Virginia</option>
										<option value="WI">Wisconsin</option>
										<option value="WY">Wyoming</option>
									</select>
									<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.state.$dirty && (!$v.registerForm.user_attribute.practice.state.required || !$v.registerForm.user_attribute.practice.state.minLength || !$v.registerForm.user_attribute.practice.state.maxLength)">State is required.</div>
								</div>
							</div>
							<div v-if="registerMethod === 'methodRegister' && registerType === 'clinical'" class="form-row">
								<div :class="{ 'col-md-6': registerForm.user_attribute.practice.location_id !== null && specialtyOptions.length > 1, col: registerForm.user_attribute.practice.location_id === null || specialtyOptions.length <= 1 }">
									<div class="card bg-light">
										<div class="card-body">
											<div class="required">
												<label class="control-label">Practice Role</label>
												<div class="form-group">
													<select v-model="registerForm.user_attribute.practice_role_id" @change="onChangePracticeRole" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice_role_id.$dirty && $v.registerForm.user_attribute.practice_role_id.$error }" name="user_attribute[practice_role_id]" id="user-attribute-practice-role-id">
														<option v-for="practiceRoleOption in practiceRoleOptions" :value="practiceRoleOption.id">{{ practiceRoleOption.label }}</option>
													</select>
													<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice_role_id.$dirty && (!$v.registerForm.user_attribute.practice_role_id.required || !$v.registerForm.user_attribute.practice_role_id.numeric)">Practice role is required.</div>
												</div>
											</div>
											<div v-if="npiRequired" class="form-group">
												<input type="text" v-model.trim="$v.registerForm.user_attribute.npi.$model" name="user_attribute[npi]" placeholder="NPI" id="npi" maxlength="10" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.npi.$dirty && $v.registerForm.user_attribute.npi.$error }" />
												<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && !$v.registerForm.user_attribute.npi.required">NPI is required.</div>
												<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && !$v.registerForm.user_attribute.npi.numeric">NPI can only contain numbers.</div>
												<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && $v.registerForm.user_attribute.npi.numeric && (!$v.registerForm.user_attribute.npi.minLength || !$v.registerForm.user_attribute.npi.maxLength)">Invalid NPI.</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="registerForm.user_attribute.practice.location_id !== null && specialtyOptions.length > 1" class="col-md-6">
									<div class="card bg-light">
										<div class="card-body">
											<div class="form-group">
												<label class="control-label">Your specialties</label>
												<treeselect
													:multiple="true"
													:options="specialtyOptions"
													v-model="tag_list"
													:flat="true"
													:value-consists-of="'ALL_WITH_INDETERMINATE'"
													:normalizer="normalizer"
													placeholder="Select one or more"
													:class="{ 'is-invalid': $v.tag_list.$dirty && $v.tag_list.$error }"
												/>
												<div class="invalid-feedback" v-if="$v.tag_list.$dirty && (!$v.tag_list.required || !$v.tag_list.minLength)">At least one specialty is required.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<template v-if="registerMethod === 'methodRegister' && registerType === 'corporate'">
								<div class="text-center my-3">
									<h5 class="card-title">Personalized Reports</h5>
								</div>
								<div class="form-row">
									<div class="form-group select col-md-6">
										<label class="control-label">Topics of Interest</label>
										<multiselect v-model="topicsOfInterest" :options="topicsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
										<select multiple name="topics_of_interest[]" style="display: none;">
											<option v-for="option in topicsOfInterest" :value="option" selected>{{ option }}</option>
										</select>
									</div>
									<div v-if="topicsOfInterest.includes('Other')" class="form-group text">
										<input type="text" name="topics_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
									</div>
									<div class="form-group select col-md-6">
										<label class="control-label">Products of Interest</label>
										<multiselect v-model="productsOfInterest" :options="productsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
										<select multiple name="products_of_interest[]" style="display: none;">
											<option v-for="option in productsOfInterest" :value="option" selected>{{ option }}</option>
										</select>
									</div>
									<div v-if="productsOfInterest.includes('Other')" class="form-group text">
										<input type="text" name="products_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
									</div>
								</div>
							</template>
							<div class="text-center my-3">
								<button type="submit" class="btn btn-success" :disabled="!isMounted || isSubmitting">Submit</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
		<footer-section />
		<request-sample />
		<contact-us />
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { required, minLength, maxLength, numeric, email, sameAs } from 'vuelidate/lib/validators'

import Navbar from './Navbar'
import ContactUs from './modals/ContactUs'
import RequestSample from './modals/RequestSample'
import FooterSection from './FooterSection'

export default {
	name: 'OphthalmicMarketTrends',
	metaInfo: {
		title: 'Ophthalmic Market Trends',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/ophthalmic-market-trends',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/omp-promo.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/ophthalmic-market-trends',
				vmid: 'canonical'
			}
		]
	},
	asyncData({ store }) {
		return Promise.all([
			store.dispatch('fetchProducts', {
				product_type_id: [14, 15, 16, 17]
			}),
			store.dispatch('fetchCompanyNames')
		])
	},
	data() {
		return {
			requestedProductId: null,
			csrfToken: '',
			gRecaptchaToken: '',
			isMounted: false,
			unsubscribe: null,
			isSubmitting: false,
			companyName: '',
			selectedSpecialtyIdx: 0,
			specialtyBack: false,
			specialtyImgs: [
				'Eye-Diagram-Cataract-Report.jpg',
				'Eye-Diagram-Refractive-Report.jpg',
				'Eye-Diagram-Glaucoma-Report.jpg',
				'Eye-Diagram-Retina-Report.jpg'
			],
			registerType: 'corporate',
			registerMethod: 'methodRegister',
			registerForm: {
				email: '',
				full_name: '',
				passwd: '',
				passwd_confirm: '',
				user_attribute: {
					practice_role_id: null,
					npi: null,
					practice: {
						name: '',
						location_id: null,
						state: ''
					}
				}
			},
			topicsOfInterest: [],
			topicsOfInterestOptions: ['Cataract', 'Refractive', 'Glaucoma', 'Retina', 'Diagnostics', 'Pharmaceuticals', 'Surgical Devices', 'Dry Eye', 'Cornea', 'Other'],
			productsOfInterest: [],
			productsOfInterestOptions: ['Comprehensive Report', 'Quarterly Update', 'Newsletter', 'Custom Research', 'Other'],
			tag_list: [],
			normalizer(node) {
				if (node.children && node.children.length) {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: node.children
					}
				} else {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: undefined
					}
				}
			},
			countryOptions: [],
			practiceRoleOptions: [],
			specialtyOptions: []
		}
	},
	computed: {
		products() {
			return this.$store.state.products
		},
		cataractReport() {
			return this.products.find(product => product.product_type_id === 14)
		},
		refractiveReport() {
			return this.products.find(product => product.product_type_id === 15)
		},
		glaucomaReport() {
			return this.products.find(product => product.product_type_id === 16)
		},
		retinaReport() {
			return this.products.find(product => product.product_type_id === 17)
		},
		companyNames() {
			return this.$store.state.companyNames
		},
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.registerForm.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.registerForm.user_attribute.practice, 'location_id', v.id)
				}
			}
		},
		npiRequired() {
			if (this.registerForm.user_attribute.practice.location_id === 1 && this.registerForm.user_attribute.practice_role_id !== null) {
				const selectedPracticeRole = this.practiceRoleOptions.find(practiceRoleOption => practiceRoleOption.id === this.registerForm.user_attribute.practice_role_id)
				return selectedPracticeRole && selectedPracticeRole.rules && !!parseInt(selectedPracticeRole.rules.npi_required, 10);
			}
			
			return false;
		}
	},
	validations() {
		const validations = {
			registerForm: {
				email: {
					required,
					email
				},
				passwd: {
					required,
					minLength: minLength(6)
				}
			}
		}
		
		if (this.registerMethod === 'methodRegister') {
			validations.registerForm.full_name = {
				required,
				minLength: minLength(3)
			}
			validations.registerForm.passwd_confirm = {
				sameAsPasswd: sameAs('passwd')
			}
			
			if (this.registerType === 'corporate') {
				validations.companyName = {
					required
				}
			} else if (this.registerType === 'clinical') {
				validations.tag_list = {}
				validations.registerForm.user_attribute = {
					practice_role_id: {
						required,
						numeric
					},
					npi: {},
					practice: {
						name: {
							required,
							minLength: minLength(3)
						},
						location_id: {
							required
						},
						state: {}
					}
				}
				
				if (this.npiRequired) {
					validations.registerForm.user_attribute.npi = {
						required,
						numeric,
						minLength: minLength(10),
						maxLength: maxLength(10)
					}
				}
				
				if (this.registerForm.user_attribute.practice.location_id === 1) {
					validations.registerForm.user_attribute.practice.state = {
						required,
						minLength: minLength(2),
						maxLength: maxLength(2)
					}
				}
				
				if (this.specialtyOptions.length > 0) {
					validations.tag_list = {
						required,
						minLength: minLength(1)
					}
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.isMounted = true
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
		this.$http.get('funcs/specialties')
			.then(response => {
				this.practiceRoleOptions = response.data.result.specialties
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'registerForm.user_attribute.practice.location_id': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$set(this.registerForm.user_attribute.practice, 'state', '')
			}
		}
	},
	methods: {
		specialtyNext() {
			if (this.selectedSpecialtyIdx < this.specialtyImgs.length - 1) {
				this.specialtyBack = false
				this.selectedSpecialtyIdx++
			}
		},
		specialtyPrev() {
			if (this.selectedSpecialtyIdx > 0) {
				this.specialtyBack = true
				this.selectedSpecialtyIdx--
			}
		},
		selectCompany(company) {
			this.$refs.corporate_company_id.value = company.id
			this.$refs.corporate_company_name.value = ''
		},
		deselectCompany() {
			this.$refs.corporate_company_id.value = ''
			this.$refs.corporate_company_name.value = this.companyName
		},
		onChangePracticeRole(e) {
			this.$http.get('funcs/specialties/' + e.target.value)
				.then(response => {
					this.specialtyOptions = response.data.result.specialties
					
					this.tag_list = []
					
					if (response.data.result.specialties.length === 1 && response.data.result.specialties[0].children.length === 0) {
						this.tag_list.push(response.data.result.specialties[0].label)
					}
				})
		},
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.isSubmitting = true
				
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.registrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		},
		login() {
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.registrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		},
		requestProduct(product) {
			this.$ga.event('report purchase button click', 'click', product.label)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Report Purchase Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		showContactUsModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Contact Us" Modal'])
			
			this.$modal.show('contactUsModal')
		}
	},
	components: {
		Multiselect,
		Treeselect,
		VueBootstrapTypeahead,
		Navbar,
		ContactUs,
		RequestSample,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
section.market-trends h1,
section.market-trends h2,
section.market-trends h3,
section.market-trends h4,
section.market-trends h5,
section.market-trends h6 {
	font-family: Catamaran, Arial, sans-serif;
}

section.market-trends .btn {
	font-family: Mulish, 'Trebuchet MS', Arial, sans-serif;
	letter-spacing: 0.8px;
}

header.market-trends-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 468px;
	
	background: url('~@assets/img/market-trends/Quarterly-Updates-Header.jpg'), $theme-secondary;
	background: url('~@assets/img/market-trends/Quarterly-Updates-Header.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	> h1 {
		position: absolute;
		top: 242px;
		color: #fff;
		width: 100%;
		text-align: center;
		font-weight: 400;
	}
}

section.market-trends.intro {
	padding-bottom: 30px;
	
	h3 {
		font-size: 30px;
		font-weight: 400;
		color: #0a5384;
		
		> span {
			color: #28a745;
		}
	}
	
	img {
		filter: drop-shadow(0px 20px 40px #0000001f);
		max-width: 120px;
	}
	
	h6 {
		color: #0a5384;
		font-size: 20px;
		font-weight: 400;
	}
}

section.market-trends.offerings {
	background-color: #f1f2f3;
	padding-bottom: 30px;
	
	h2 {
		font-size: 40px;
		font-weight: 400;
		color: #0a5384;
	}
	
	p {
		color: #777;
		font-size: 18px;
	}
	
	.slide-leave-active,
	.slide-enter-active {
		transition: all 1s;
	}
	.slide-enter {
		transform: translate(100%, 0);
		opacity: 0;
	}
	.slide-leave-to {
		transform: translate(-100%, 0);
		opacity: 0;
	}

	.slideback-leave-active,
	.slideback-enter-active {
		transition: all 1s;
	}
	.slideback-enter {
		transform: translate(-100%, 0);
		opacity: 0;
	}
	.slideback-leave-to {
		transform: translate(100%, 0);
		opacity: 0;
	}
	
	.specialty-slider-btn {
		display: inline-block;
		cursor: pointer;
		
		&:hover,
		&:focus {
			filter: drop-shadow(0 0 0.2rem rgba(0, 123, 255, 0.25));
		}
	}
	
	.specialty-slider {
		overflow: hidden;
		position: relative;
		padding-bottom: 85%;
		
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
		}
	}
	
	.card {
		&:not(.selected) {
			opacity: 0.7;
		}
		
		&:hover:not(.selected) {
			cursor: pointer;
			opacity: 1;
		}
	}
	
	.card-top-arrow {
		display: none;
	}
	
	.card.selected .card-top-arrow {
		display: block;
		
		&:after {
			content: '';
			position: absolute;
			top: -24px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 0;
			height: 0;
			border-right: 25px solid transparent;
			border-left: 25px solid transparent;
		}
	}
	
	.card-header h3 {
		font-size: 28px;
		font-weight: 400;
	}
	
	.card.selected {
		.card-header h3 {
			color: #fff;
			font-weight: 500;
		}
		
		.card-body li {
			color: #111;
		}
	}
	
	.card-body {
		h5 {
			font-size: 20px;
			font-weight: 500;
		}
		
		ul {
			list-style-type: none;
			padding-left: 0;
		}
		
		li {
			background-repeat: no-repeat;
			background-size: 24px;
			background-position-y: 2px;
			line-height: 28px;
			padding-left: 38px;
			padding-bottom: 5px;
			color: #777;
			font-size: 16px;
		}
		
		.btn {
			color: #fff;
		}
	}
	
	.offerings-cataract-report {
		&.selected {
			box-shadow: 0 1rem 3rem rgba(10, 83, 132, 0.5) !important;
			
			.card-top-arrow {
				&:after {
					border-bottom: 25px solid #0a5384;
				}
			}
			
			.card-header {
				background-color: #0a5384;
			}
		}
		
		.card-header {
			background-color: rgba(10, 83, 132, 0.25);
		}
		
		h3,
		h5 {
			color: #0a5384;
		}
		
		.card-body {
			li {
				background-image: url('~@assets/img/market-trends/Checkmark-Blue.svg');
			}
			
			.btn {
				background-color: #0a5384;
			}
		}
	}
	
	.offerings-refractive-report {
		&.selected {
			box-shadow: 0 1rem 3rem rgba(10, 83, 132, 0.5) !important;
			
			.card-top-arrow {
				&:after {
					border-bottom: 25px solid #207392;
				}
			}
			
			.card-header {
				background-color: #207392;
			}
		}
		
		.card-header {
			background-color: rgba(10, 83, 132, 0.25);
		}
		
		h3,
		h5 {
			color: #207392;
		}
		
		.card-body {
			li {
				background-image: url('~@assets/img/market-trends/Checkmark-Teal.svg');
			}
			
			.btn {
				background-color: #207392;
			}
		}
	}
	
	.offerings-glaucoma-report {
		&.selected {
			box-shadow: 0 1rem 3rem rgba(54, 148, 162, 0.5) !important;
			
			.card-top-arrow {
				&:after {
					border-bottom: 25px solid #3694a2;
				}
			}
			
			.card-header {
				background-color: #3694a2;
			}
		}
		
		.card-header {
			background-color: rgba(54, 148, 162, 0.25);
		}
		
		h3,
		h5 {
			color: #3694a2;
		}
		
		.card-body {
			li {
				background-image: url('~@assets/img/market-trends/Checkmark-Primary-Teal.svg');
			}
			
			.btn {
				background-color: #3694a2;
			}
		}
	}
	
	.offerings-retina-report {
		&.selected {
			box-shadow: 0 1rem 3rem rgba(40, 167, 69, 0.5) !important;
			
			.card-top-arrow {
				&:after {
					border-bottom: 25px solid #28a745;
				}
			}
			
			.card-header {
				background-color: #28a745;
			}
		}
		
		.card-header {
			background-color: rgba(40, 167, 69, 0.25);
		}
		
		h3,
		h5 {
			color: #28a745;
		}
		
		.card-body {
			li {
				background-image: url('~@assets/img/market-trends/Checkmark-Green.svg');
			}
			
			.btn {
				background-color: #28a745;
			}
		}
	}
}

section.market-trends.deliverables {
	padding-bottom: 30px;
	
	background: transparent url('~@assets/img/market-trends/Blue-Background-With-Chart-Overlay.jpg');
	background-position-x: right;
	@include background-cover;
	
	h2 {
		font-size: 40px;
		font-weight: 400;
		color: #fff;
	}
	
	.card-body {
		h5 {
			font-size: 24px;
			color: #0a5384;
			font-weight: 400;
		}
		
		p {
			font-size: 20px;
			color: #777;
		}
	}
}

section.market-trends.sources {
	padding-bottom: 30px;
	
	h2 {
		font-size: 40px;
		font-weight: 400;
		color: #0a5384;
	}
	
	.card-body {
		background: transparent linear-gradient(180deg, #229EB6 0%, #127497 100%) 0% 0% no-repeat;
		
		h5 {
			font-size: 24px;
			color: #fff;
			font-weight: 400;
		}
	}
}

section.market-trends.highlights {
	background-color: #f1f2f3;
	padding-bottom: 0;
	
	h2 {
		font-size: 40px;
		font-weight: 400;
		color: #0a5384;
	}
	
	p {
		color: #777;
		font-size: 18px;
	}
	
	.card-group {
		display: block;
		
		.card.report-overview {
			h5 {
				color: #0a5384;
				font-size: 30px;
				font-weight: 500;
			}
			
			ul {
				list-style-type: none;
				padding-left: 0;
			}
			
			li {
				background-image: url('~@assets/img/market-trends/Checkmark-Primary-Teal.svg');
				background-repeat: no-repeat;
				line-height: 32px;
				padding-left: 38px;
				padding-bottom: 5px;
				color: #777;
				font-size: 18px;
			}
			
			.btn {
				&.btn-purchase {
					background-color: #28a745;
					color: #fff;
				}
				&.btn-purchase-outline {
					border: 1px solid #28a745;
					color: #28a745;
				}
			}
		}
		
		.card.report-cover {
			background: transparent linear-gradient(180deg, #229EB6 0%, #127497 100%) 0% 0% no-repeat;
			
			img {
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important;
			}
		}
	}
	
	@media (min-width: 768px) {
		.card-group {
			display: flex;
			flex-flow: row wrap;
		}
	}
}

section.market-trends.register {
	width: 100%;
	padding-top: 270px;
	margin-bottom: 0;
	box-shadow: none;
	
	background: url('~@assets/img/market-trends/Chart-Background.jpg'), #1681a1;
	@include background-cover;
	background-position-x: center;
	background-position-y: top;
	background-repeat: no-repeat;
	
	h3 {
		font-size: 40px;
		font-weight: 400;
	}
	
	.btn-bottom-arrow {
		display: none;
	}
	
	.card {
		max-width: unset;
	}
	
	.btn-register {
		color: #fff;
		background-color: transparent;
		border: 1px solid #fff;
	}
	
	.selected {
		.btn-register {
			color: #28a745;
			background-color: #fff;
		}
		
		.btn-bottom-arrow {
			display: block;
			
			&:after {
				content: '';
				position: absolute;
				bottom: -11px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 0;
				height: 0;
				border-right: 15px solid transparent;
				border-top: 12px solid #fff;
				border-left: 15px solid transparent;
			}
		}
	}
	
	h5.card-title {
		color: #0a5384;
		font-size: 1.4rem;
		font-weight: 600;
	}
	
	label {
		color: #0a5384;
		font-weight: bold;
	}
	
	h3,
	p {
		color: #fff;
	}
}
</style>