<template>
	<div>
		<navbar :activeContext="activeContext" />
		<header class="masthead">
			<div class="container h-100">
				<div class="row h-100 align-items-center justify-content-between">
					<div class="col-12">
						<div class="header-content mx-auto text-center">
							<h1>Market Scope</h1>
							<h2 class="mb-5">Trusted Global Partner of Industry and Medical Professionals for <strong>Ophthalmic Market Research</strong>, <strong class="text-nowrap">Primary Research</strong>, <strong>Independent Perspective</strong>, &amp; <strong class="text-nowrap">Objective Analysis</strong></h2>
							<div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
								<h4><i class="fas fa-sliders-h small"></i> Choose Your Experience</h4>
								<div class="context-switch-button">
									<span v-show="activeContext !== 'combined'" class="active" ref="contextSwitchActive" :style="activeContextSliderStyle"></span>
									<button type="button" :class="{ 'context-switch-button-case': true, 'p-2': true, left: true, 'active-case': activeContext === 'industry' }" ref="contextSwitchBtnIndustry" @click="contextSwitchIndustry">Industry Professional</button>
									<button type="button" :class="{ 'context-switch-button-case': true, 'p-2': true, right: true, 'active-case': activeContext === 'medical' }" ref="contextSwitchBtnMedical" @click="contextSwitchMedical">Medical Professional</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section class="customers" id="customers">
			<div class="container">
				<div class="row mb-3">
					<div class="mx-auto text-center">
						<h2 class="section-heading" v-html="currentCustomerHeading"></h2>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="logos">
							<div class="logos-slide" :style="styleAnimation">
								<a v-for="(slide, i) in customerSlides" :key="i" :href="slide.href" :title="slide.title" target="_blank"><img :src="require('@assets/img/customers/' + slide.img)" /></a>
							</div>
							<div class="logos-slide" :style="styleAnimation">
								<a v-for="(slide, i) in customerSlides" :key="i" :href="slide.href" :title="slide.title" target="_blank"><img :src="require('@assets/img/customers/' + slide.img)" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section v-if="products.length && (activeContext === 'combined' || activeContext === 'industry')" class="latest" id="latest">
			<div class="container">
				<div class="section-heading text-center">
					<img :src="require('@assets/img/icons/MarketScope-Icons-05.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;">
					<h2>Latest Market Reports</h2>
					<p>Market Scope's industry reports are meticulously prepared by a dedicated team of in-house consultants/analysts with over 100 years of collective experience.</p>
				</div>
				<div class="row justify-content-center align-items-center carousel-container">
					<div class="col-lg-3">
						<ul class="list-unstyled">
							<li v-if="products[slide].pagecount_visible && products[slide].document_original_pagecount" class="media">
								<div style="background: #e8a520;">
									{{ products[slide].document_original_pagecount }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">Comprehensive Pages</h5>
								</div>
							</li>
							<li v-if="typeof products[slide].product_metadata_attributes[1] !== 'undefined'" class="media mt-4">
								<div style="background: #b92f48;">
									{{ products[slide].product_metadata_attributes[1].quantity }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">{{ products[slide].product_metadata_attributes[1].highlight }}</h5>
								</div>
							</li>
							<li v-if="typeof products[slide].product_metadata_attributes[3] !== 'undefined'" class="media mt-4">
								<div style="background: #435054;">
									{{ products[slide].product_metadata_attributes[3].quantity }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">{{ products[slide].product_metadata_attributes[3].highlight }}</h5>
								</div>
							</li>
						</ul>
					</div>
					<div class="col-lg-6">
						<div class="latest-content">
							<div class="device-wrapper w-100">
								<div class="device" v-lazy:background-image="require('@assets/img/ipad-frame.png')">
									<div class="screen">
										<b-carousel id="productsCarousel"
											background="#ffffff"
											:interval="carouselInterval"
											v-model="slide"
										>
											<b-carousel-slide v-for="(product, i) in products" :key="i">
												<img slot="img" class="d-block img-fluid w-100" width="612" height="792" v-lazy="product.cover_img" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" @click="$router.push({ name: 'Report', params: { id: product.id, slug: product.slug }, hash: '#reports' })" />
											</b-carousel-slide>
										</b-carousel>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<ul class="list-unstyled">
							<li v-if="typeof products[slide].product_metadata_attributes[0] !== 'undefined'" class="media">
								<div style="background: #07547e;">
									{{ products[slide].product_metadata_attributes[0].quantity }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">{{ products[slide].product_metadata_attributes[0].highlight }}</h5>
								</div>
							</li>
							<li v-if="typeof products[slide].product_metadata_attributes[2] !== 'undefined'" class="media mt-4">
								<div style="background: #86c34a;">
									{{ products[slide].product_metadata_attributes[2].quantity }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">{{ products[slide].product_metadata_attributes[2].highlight }}</h5>
								</div>
							</li>
							<li v-if="typeof products[slide].product_metadata_attributes[4] !== 'undefined'" class="media mt-4">
								<div style="background: #515585;">
									{{ products[slide].product_metadata_attributes[4].quantity }}
								</div>
								<div class="media-body align-self-center ml-3">
									<h5 class="m-0">{{ products[slide].product_metadata_attributes[4].highlight }}</h5>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div v-if="slide in products" class="row justify-content-center carousel-controls">
					<div class="col-1">
						<a href="#" @click.prevent="slidePrev"><i class="fas fa-arrow-circle-left"></i></a>
					</div>
					<div class="col-8 text-center">
						<h3>
							<template v-if="products[slide].published">
								<router-link :to="{ name: 'Report', params: { id: products[slide].id, slug: products[slide].slug } }">{{ products[slide].label }}</router-link>
							</template>
							<template v-else>
								<router-link :to="{ name: 'Report', params: { id: products[slide].id, slug: products[slide].slug } }"><span><i class="fa fa-clock"></i> COMING SOON<template v-if="products[slide].coming_soon_text">: {{ products[slide].coming_soon_text }}</template></span><br />{{ products[slide].label }}</router-link>
							</template>
						</h3>
						<div v-html="products[slide].description" class="text-left description"></div>
					</div>
					<div class="col-1">
						<a href="#" @click.prevent="slideNext"><i class="fas fa-arrow-circle-right"></i></a>
					</div>
				</div>
				<div>
					<div class="mx-auto text-center">
						<router-link :to="{ name: 'Reports', query: { page: 1 } }" class="btn btn-secondary mt-4"><i class="fas fa-list"></i> Show Latest Reports</router-link>
					</div>
				</div>
			</div>
		</section>
		<section v-if="activeContext === 'medical'" class="physicians" id="physicians">
			<div class="container">
				<div class="section-heading text-center">
					<img v-lazy="require('@assets/img/icons/MarketScope-Icons-04.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
					<h2>Provider Surveys &amp; Interviews</h2>
					<h5>Grab a seat at the table and join the thousands of <strong>ophthalmologists and optometrists</strong> from <strong>over 60 countries</strong> who are helping shape the <strong>next generation</strong> of diagnostics, pharmaceuticals, devices, and equipment by sharing your opinion and experience. <strong>Make your voice heard!</strong></h5>
				</div>
				<div class="row pt-5 align-items-center">
					<div class="col-lg-7 pr-lg-5 pr-3 pb-lg-0 pb-5" style="z-index: 1;">
						<picture>
							<source media="(max-width: 768px)" :srcset="require('@assets/img/physicians/physicians-innovation-mobile.png')">
							<img v-lazy="require('@assets/img/physicians/physicians-innovation.png')" class="img-fluid" alt="Table of Innovation">
						</picture>
					</div>
					<div class="col-lg-5 physicians-content">
						<h4><i class="fas fa-check-circle"></i> Choose Your Own Adventure</h4>
						<p>Participation options include weekly polls, special sponsored surveys, syndicated quarterly surveys, focus groups, and interviews, each with varying levels of honoraria.</p>
						<h4><i class="fas fa-check-circle"></i> Safe, Secure &amp; On Any Device</h4>
						<p>We follow all industry standards to ensure confidentiality between participants and clients. Start and stop surveys anytime on our free Market Scope+ App or on desktop, each with a personalized dashboard just for you.</p>
						<h4><i class="fas fa-check-circle"></i> Pre-Qualified Invitations</h4>
						<p>We individually verify all potential participants before each survey or interview, ensuring that only licensed eye care professionals are invited and they are never kicked out or disqualified.</p>
						<h4><i class="fas fa-check-circle"></i> Better Data = Better Decisions</h4>
						<p class="mb-0">A team of industry experts and analysts review each data point, so you can be confident in the results and trends provided in our reports.</p>
					</div>
				</div>
				<div class="row py-4">
					<div class="col text-center">
						<router-link to="/plus" class="btn btn-secondary mt-3">Learn More</router-link>
						<a href="#" v-scroll-to="'#register'" class="btn btn-secondary mt-3 ml-2">Register Today</a>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-lg-8 pt-3 text-center physicians-footer">
						<h3 class="mb-3">Download the Market Scope+ app to get started!</h3>
						<div class="mb-3">
							<a href="https://apps.apple.com/za/app/market-scope/id1642558540" target="_blank"><img v-lazy="require('@assets/img/plus/plus-apple-download.png')" /></a>
							<a href="https://play.google.com/store/apps/details?id=com.marketscope.app" target="_blank" class="ml-0 ml-lg-3"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
						</div>
					</div>
					<div class="col-lg-4 text-center">
						<img v-lazy="require('@assets/img/physicians/physicians-app-screen.png')" class="img-fluid" alt="Market Scope+ App Screen" />
					</div>
				</div>
			</div>
		</section>
		<section class="news" id="news">
			<div class="container">
				<div class="mb-3">
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-08.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h2 class="section-heading">Latest Industry News &amp; Trends</h2>
					</div>
				</div>
				<div class="row">
					<div v-for="article in articles" class="col-12 col-md-6 col-lg-3">
						<div class="card" @click="showArticle(article)">
							<div class="card-header">
								{{ new Date(article.publish_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }}
								<div class="float-right text-right">
									<template v-for="tag in article.landing_tags">
										<span v-if="tag.id" class="badge badge-pill" :style="{ backgroundColor: tag.color }">{{ tag.label }}</span>
									</template>
								</div>
							</div>
							<div class="card-body">
								<h5 class="card-title">{{ article.title }}</h5>
								<img v-if="article.preview_img_url" class="img-thumbnail d-block mx-auto" v-lazy="article.preview_img_url" :alt="(article.title).trim()" />
								<p class="card-text" v-html="article.content_preview"></p>
							</div>
							<div class="card-footer text-center">
								<span v-if="article.content">Read Article</span>
								<span v-else><i class="fas fa-lock"></i> Read Article</span>
							</div>
						</div>
						<modal :name="'articleModal' + article.id" class="article-modal" height="auto" :scrollable="true" :reset="true" :adaptive="true" transition="nice-modal-fade">
							<div class="modal-header">
								<h5 class="modal-title" :id="'article-modal-title-' + article.id">
									{{ article.title }}
									<br v-if="article.author || article.author_custom !== ''" />
									<small v-if="article.author || article.author_custom !== ''" class="text-muted">Author: {{ article.author ? (article.author.user_attribute.title !== '' ? article.author.full_name + ', ' + article.author.user_attribute.title : article.author.full_name) : article.author_custom }}</small>
								</h5>
								<button type="button" class="close" @click="$modal.hide('articleModal' + article.id)">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div v-if="article.content" class="modal-body">
								<div v-html="article.content"></div>
								<div class="text-center pb-2">
									<a href="#" @click.prevent="jumpToRegister(article)" class="btn btn-success">Register Now</a>
								</div>
								<div v-if="article.recommended_products.length" class="card bg-light mt-3">
									<div class="card-body">
										<h6>Recommended Products</h6>
										<div class="container">
											<div class="row d-flex align-items-start">
												<div class="recommended-product col-md-3 p-1 bg-white border border-light" v-for="product in article.recommended_products">
													<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
														<h6 class="text-center m-0 p-1">{{ product.label }}</h6>
														<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="modal-body">
								<p v-if="article.preview_img_url" style="text-align: center;">
									<img class="img-thumbnail d-block mx-auto" v-lazy="article.preview_img_url" :alt="(article.title).trim()" />
								</p>
								<p v-html="article.content_teaser"></p>
								<div class="text-center pb-4">
									<a href="#" @click.prevent="jumpToRegister(article)" class="btn btn-success">Register Now</a>
								</div>
								<div v-if="article.associated_products.length || (article.associated_subscriptions.length && article.associated_subscriptions.some(subscription => subscription.products[0]))" class="card bg-light">
									<div class="card-body">
										<h6>Purchasing one of the following products will open up access to this article's content, which is also available in each comprehensive report/subscription.</h6>
										<div class="container">
											<div class="row d-flex align-items-start">
												<div class="associated-product col-md-3 p-1 bg-white border border-light" v-for="product in article.associated_products">
													<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
														<h6 class="text-center m-0 p-1">{{ product.attributes }}</h6>
														<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.attributes" />
													</router-link>
												</div>
												<div class="associated-product col-md-3 p-1 bg-white border border-light" v-for="subscription in article.associated_subscriptions" v-if="subscription.products[0]">
													<router-link :to="{ name: 'Report', params: { id: subscription.products[0].id, slug: subscription.products[0].slug } }">
														<h6 class="text-center m-0 p-1">{{ subscription.name }}</h6>
														<img v-if="subscription.products[0].document_original_filename || subscription.products[0].document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + subscription.products[0].id + '/' + (subscription.products[0].document_sample_filename ? subscription.products[0].document_sample_filename.substr(0, subscription.products[0].document_sample_filename.lastIndexOf('.')) : subscription.products[0].document_original_filename.substr(0, subscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="subscription.products[0].cover_img_alt ? subscription.products[0].cover_img_alt : (subscription.name + ' - ' + subscription.products[0].attributes)" />
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="article.recommended_products.length" class="card bg-light mt-3">
									<div class="card-body">
										<h6>Recommended Products</h6>
										<div class="container">
											<div class="row d-flex align-items-start">
												<div class="recommended-product col-md-3 p-1 bg-white border border-light" v-for="product in article.recommended_products">
													<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
														<h6 class="text-center m-0 p-1">{{ product.label }}</h6>
														<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<no-ssr>
									<vue-goodshare-linked-in
										:page_url="'https://www.market-scope.com/pages/news/' + article.id + '/' + article.slug"
										:page-title="article.title + ' | Market Scope'"
										has_icon
									/>
									<vue-goodshare-facebook
										:page_url="'https://www.market-scope.com/pages/news/' + article.id + '/' + article.slug"
										:page-title="article.title + ' | Market Scope'"
										has_icon
									/>
									<vue-goodshare-twitter
										:page_url="'https://www.market-scope.com/pages/news/' + article.id + '/' + article.slug"
										:page-title="article.title + ' | Market Scope'"
										has_icon
									/>
								</no-ssr>
								<a @click.prevent="showShareArticleModal(article)" class="button-social email__design__flat mr-auto">
									<i class="icon-email"></i>
								</a>
								<small class="text-muted">{{ new Date(article.publish_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }}</small>
							</div>
						</modal>
					</div>
				</div>
				<div class="text-center mt-3">
					<router-link to="/pages/news" class="btn btn-primary">Show More Articles</router-link>
				</div>
			</div>
		</section>
		<section v-if="activeContext === 'combined'" class="physicians" id="physicians">
			<div class="container">
				<div class="section-heading text-center">
					<img v-lazy="require('@assets/img/icons/MarketScope-Icons-04.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
					<h2>Provider Surveys &amp; Interviews</h2>
					<h5>Grab a seat at the table and join the thousands of <strong>ophthalmologists and optometrists</strong> from <strong>over 60 countries</strong> who are helping shape the <strong>next generation</strong> of diagnostics, pharmaceuticals, devices, and equipment by sharing your opinion and experience. <strong>Make your voice heard!</strong></h5>
				</div>
				<div class="row pt-5 align-items-center">
					<div class="col-lg-7 pr-lg-5 pr-3 pb-lg-0 pb-5" style="z-index: 1;">
						<picture>
							<source media="(max-width: 768px)" :srcset="require('@assets/img/physicians/physicians-innovation-mobile.png')">
							<img v-lazy="require('@assets/img/physicians/physicians-innovation.png')" class="img-fluid" alt="Table of Innovation">
						</picture>
					</div>
					<div class="col-lg-5 physicians-content">
						<h4><i class="fas fa-check-circle"></i> Choose Your Own Adventure</h4>
						<p>Participation options include weekly polls, special sponsored surveys, syndicated quarterly surveys, focus groups, and interviews, each with varying levels of honoraria.</p>
						<h4><i class="fas fa-check-circle"></i> Safe, Secure &amp; On Any Device</h4>
						<p>We follow all industry standards to ensure confidentiality between participants and clients. Start and stop surveys anytime on our free Market Scope+ App or on desktop, each with a personalized dashboard just for you.</p>
						<h4><i class="fas fa-check-circle"></i> Pre-Qualified Invitations</h4>
						<p>We individually verify all potential participants before each survey or interview, ensuring that only licensed eye care professionals are invited and they are never kicked out or disqualified.</p>
						<h4><i class="fas fa-check-circle"></i> Better Data = Better Decisions</h4>
						<p class="mb-0">A team of industry experts and analysts review each data point, so you can be confident in the results and trends provided in our reports.</p>
					</div>
				</div>
				<div class="row py-4">
					<div class="col text-center">
						<router-link to="/plus" class="btn btn-secondary mt-3">Learn More</router-link>
						<a href="#" v-scroll-to="'#register'" class="btn btn-secondary mt-3 ml-2">Register Today</a>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-lg-8 pt-3 text-center physicians-footer">
						<h3 class="mb-3">Download the Market Scope+ app to get started!</h3>
						<div class="mb-3">
							<a href="https://apps.apple.com/za/app/market-scope/id1642558540" target="_blank"><img v-lazy="require('@assets/img/plus/plus-apple-download.png')" /></a>
							<a href="https://play.google.com/store/apps/details?id=com.marketscope.app" target="_blank" class="ml-0 ml-lg-3"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
						</div>
					</div>
					<div class="col-lg-4 text-center">
						<img v-lazy="require('@assets/img/physicians/physicians-app-screen.png')" class="img-fluid" alt="Market Scope+ App Screen" />
					</div>
				</div>
			</div>
		</section>
		<section v-if="activeContext === 'combined' || activeContext === 'industry'" class="about bg-primary" id="about">
			<div class="container">
				<div class="mb-3">
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-03.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h2 class="section-heading">Market Scope Methodology</h2>
						<p>A Multi-Step Approach for Accurate &amp; Timely Data</p>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<b-tabs nav-class="nav-justified">
							<b-tab active>
								<template slot="title">
									<img v-lazy="require('@assets/img/Homepage-CollectData-Methodology.png')" class="img-fluid" />
									<h4>Collect Data</h4>
								</template>
								<div class="container">
									<div class="row">
										<div class="col-lg-6 mb-3">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-01.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Proprietary data</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> 20+ years of physician survey data</li>
												<li><i class="fas fa-check-circle"></i> Disease models</li>
												<li><i class="fas fa-check-circle"></i> Cultivated database of physicians, ASCs, and hospitals</li>
												<li><i class="fas fa-check-circle"></i> Insight from our team of in-house industry experts</li>
											</ul>
										</div>
										<div class="col-lg-6">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-02.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Public data</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> Attendance and participation at major, worldwide meetings</li>
												<li><i class="fas fa-check-circle"></i> Public health systems and registries</li>
												<li><i class="fas fa-check-circle"></i> Clinical study data</li>
												<li><i class="fas fa-check-circle"></i> Analysis of company-published financial reports</li>
											</ul>
										</div>
									</div>
								</div>
							</b-tab>
							<b-tab>
								<template slot="title">
									<img v-lazy="require('@assets/img/Homepage-Analyze-Methodology.png')" class="img-fluid" />
									<h4>Model &amp; Analyze Market</h4>
								</template>
								<div class="container">
									<div class="row">
										<div class="col-lg-4 mb-3">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-05.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Extensive analysis</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> Integrated disease models</li>
												<li><i class="fas fa-check-circle"></i> Expert-based segmentation</li>
											</ul>
										</div>
										<div class="col-lg-4 mb-3">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-06.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Integrate</strong> and <strong>reconcile</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> Continuous feedback loop</li>
												<li><i class="fas fa-check-circle"></i> Integration of survey data</li>
												<li><i class="fas fa-check-circle"></i> Triangulation of results</li>
												<li><i class="fas fa-check-circle"></i> Cross-model analysis</li>
												<li><i class="fas fa-check-circle"></i> Reconciliation to company revenues</li>
											</ul>
										</div>
										<div class="col-lg-4">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-07.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Produce products</strong> covering key medical markets</h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> Medical devices</li>
												<li><i class="fas fa-check-circle"></i> Drugs/biologics</li>
												<li><i class="fas fa-check-circle"></i> Equipment</li>
												<li><i class="fas fa-check-circle"></i> Providers</li>
											</ul>
										</div>
									</div>
								</div>
							</b-tab>
							<b-tab>
								<template slot="title">
									<img v-lazy="require('@assets/img/Homepage-Incorporate-Methodology.png')" class="img-fluid" />
									<h4>Gather &amp; Incorporate Feedback</h4>
								</template>
								<div class="container">
									<div class="row">
										<div class="col-lg-6 mb-3">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-03.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Gather feedback</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> New market insights</li>
												<li><i class="fas fa-check-circle"></i> Client requests</li>
												<li><i class="fas fa-check-circle"></i> Landmark study evaluations</li>
											</ul>
										</div>
										<div class="col-lg-6">
											<div class="media">
												<img v-lazy="require('@assets/img/icons/Methodology-Icons-04.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
												<h4 class="align-self-center m-0"><strong>Incorporate</strong> and <strong>revise</strong></h4>
											</div>
											<ul>
												<li><i class="fas fa-check-circle"></i> Forecasting models</li>
												<li><i class="fas fa-check-circle"></i> Analytical tools and methods</li>
											</ul>
										</div>
									</div>
								</div>
							</b-tab>
						</b-tabs>
					</div>
				</div>
				<div class="row">
					<div class="mx-auto">
						<router-link to="/pages/methodology" class="btn btn-primary mt-5">Learn More</router-link>
					</div>
				</div>
			</div>
		</section>
		<section class="register" id="register">
			<div class="container">
				<div class="header-row mb-4">
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-09.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h2 class="section-heading">Join Market Scope Today</h2>
					</div>
				</div>
				<div class="row justify-content-center registration-containers">
					<template v-if="activeContext === 'combined'">
						<div class="col-lg-5">
							<corporate-registration containerId="main-corporate-container" />
						</div>
						<div class="col-lg-5">
							<campaign-clinical-registration v-if="campaign && campaign.fields_list.length" containerId="main-clinical-container" />
							<clinical-registration v-else containerId="main-clinical-container" />
						</div>
					</template>
					<template v-else>
						<div v-show="activeContext === 'industry'" class="col-lg-10">
							<corporate-registration containerId="main-corporate-container" />
						</div>
						<div v-show="activeContext === 'medical'" class="col-lg-10">
							<campaign-clinical-registration v-if="campaign && campaign.fields_list.length" containerId="main-clinical-container" />
							<clinical-registration v-else containerId="main-clinical-container" />
						</div>
					</template>
				</div>
			</div>
		</section>
		<footer-section />
		<share-article />
	</div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { mapState } from 'vuex'
import { BCard, BCardGroup, BCardBody, BCardImgLazy, BTabs, BTab, BCarousel, BCarouselSlide, BLink } from 'bootstrap-vue'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'

import Navbar from './Navbar'
import CorporateRegistration from './forms/CorporateRegistration'
import ClinicalRegistration from './forms/ClinicalRegistration'
import CampaignClinicalRegistration from './forms/CampaignClinicalRegistration'
import FooterSection from './FooterSection'
import ShareArticle from './modals/ShareArticle'

export default {
	name: 'Landing',
	metaInfo() {
		let path = ''
		if (this.activeContext === 'industry') {
			path = '/industry'
		} else if (this.activeContext === 'medical') {
			path = '/medical'
		}
		
		let meta = [
			{
				property: 'og:url',
				content: '//www.market-scope.com' + path,
				vmid: 'og:url'
			}
		]
		
		if (this.activeContext === 'industry') {
			meta.push({
				property: 'og:title',
				content: 'Industry-Leading Ophthalmic Market Data for Companies',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			})
			meta.push({
				property: 'og:description',
				content: 'Market Scope is the trusted partner of more than 500 companies around the world for ophthalmic market data and curated news, primary research, independent perspective, and objective analysis.',
				vmid: 'og:description'
			})
			meta.push({
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/landing-share.jpg',
				vmid: 'og:image'
			})
		} else if (this.activeContext === 'medical') {
			meta.push({
				property: 'og:title',
				content: 'Make your Voice Heard with Market Scope',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			})
			meta.push({
				property: 'og:description',
				content: 'Market Scope provides opportunities for ophthalmologists and optometrists around the world to help shape the next generation of diagnostics, pharmaceuticals, devices, and equipment by sharing their opinion and experience through weekly polls, surveys, focus groups, and interviews.',
				vmid: 'og:description'
			})
			meta.push({
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/landing-medical-share.jpg',
				vmid: 'og:image'
			})
		} else {
			meta.push({
				property: 'og:title',
				content: 'Ophthalmic Market Coverage for Eyecare Companies and Providers',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			})
			meta.push({
				property: 'og:description',
				content: 'Market Scope is the trusted partner of more than 500 companies and thousands of providers around the world for ophthalmic market data, curated news, primary research, independent perspective, and objective analysis.',
				vmid: 'og:description'
			})
			meta.push({
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/landing-share.jpg',
				vmid: 'og:image'
			})
		}
		
		return {
			title: 'Market Scope',
			titleTemplate: null,
			meta: meta,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com' + path,
					vmid: 'canonical'
				},
				{
					rel: 'prefetch',
					href: require('@assets/img/MarketScope-Home-Header-combined.jpg')
				},
				{
					rel: 'prefetch',
					href: require('@assets/img/MarketScope-Home-Header-industry.jpg')
				},
				{
					rel: 'prefetch',
					href: require('@assets/img/MarketScope-Home-Header-medical.jpg')
				}
			]
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', 'Market Scope'])
		})
	},
	beforeRouteUpdate(to, from, next) {
		next()
		
		const _paq = window._paq = window._paq || []
		if (this.prevRoute && this.prevRoute.fullPath) {
			_paq.push(['setReferrerUrl', window.location.origin + this.$router.resolve(this.prevRoute.fullPath).href])
		}
		_paq.push(['setCustomUrl', window.location.origin + this.$router.resolve(this.$route.fullPath).href])
		_paq.push(['trackPageView', 'Market Scope'])
	},
	asyncData({ store }) {
		return Promise.all([
			store.dispatch('fetchArticles', {
				limit: 8
			}),
			store.dispatch('fetchProducts', {
				limit: 10
			})
		])
	},
	data() {
		return {
			slide: 0,
			carouselInterval: 3000,
			mastheadBgImgs: {
				combined: 'url(' + require('@assets/img/MarketScope-Home-Header-combined.jpg') + ')',
				industry: 'url(' + require('@assets/img/MarketScope-Home-Header-industry.jpg') + ')',
				medical: 'url(' + require('@assets/img/MarketScope-Home-Header-medical.jpg') + ')'
			},
			customerHeadings: {
				combined: '<span class="nowrap">Over&nbsp;500&nbsp;Clients,&nbsp;</span><span class="nowrap">Large&nbsp;&amp;&nbsp;Small&nbsp;from&nbsp;</span><span class="nowrap">Around&nbsp;the&nbsp;World,&nbsp;</span><span class="nowrap">Have&nbsp;Trusted&nbsp;</span><span class="nowrap">Market&nbsp;Scope&nbsp;</span><span class="nowrap">Data&nbsp;&amp;&nbsp;Analysis</span>',
				industry: '<span class="nowrap">Over&nbsp;500&nbsp;Clients,&nbsp;</span><span class="nowrap">Large&nbsp;&amp;&nbsp;Small&nbsp;from&nbsp;</span><span class="nowrap">Around&nbsp;the&nbsp;World,&nbsp;</span><span class="nowrap">Have&nbsp;Trusted&nbsp;</span><span class="nowrap">Market&nbsp;Scope&nbsp;</span><span class="nowrap">Data&nbsp;&amp;&nbsp;Analysis</span>',
				medical: '<span class="nowrap">Over&nbsp;1,500&nbsp;Practices&nbsp;from&nbsp;</span><span class="nowrap">Over&nbsp;80&nbsp;Countries&nbsp;have&nbsp;</span><span class="nowrap">Participated&nbsp;in&nbsp;</span><span class="nowrap">Market&nbsp;Scope&nbsp;Research</span>'
			},
			customers: [
				{
					title: 'AcuFocus',
					img: 'acufocus.png',
					href: 'https://acufocus.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Adler Eye Institute',
					img: 'adler.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Alcon',
					img: 'alcon.jpg',
					href: 'https://www.alcon.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'ArcScan',
					img: 'arcscan.png',
					href: 'https://www.arcscan.com/',
					contexts: ['medical']
				},
				{
					title: 'Arena Eye Surgeons',
					img: 'arena.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Atia Vision',
					img: 'atia-vision.png',
					href: 'https://atiavision.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Aurion Biotech',
					img: 'aurion.png',
					href: 'https://aurionbiotech.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Autocam Medical',
					img: 'autocam.png',
					href: 'https://www.autocam-medical.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Avellino',
					img: 'avellino.png',
					href: 'https://www.avellino.ai/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Azura',
					img: 'azura.png',
					href: 'https://azuraophthalmics.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Barenburg Eye Associates',
					img: 'barenburg.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Bausch + Lomb',
					img: 'bausch-lomb.png',
					href: 'https://www.bausch.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Beach Eye Care',
					img: 'beach.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'BELKIN Vision',
					img: 'belkin-vision.png',
					href: 'https://belkin-vision.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'BVI',
					img: 'bvi.png',
					href: 'https://www.bvimedical.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'CareCredit',
					img: 'carecredit.png',
					href: 'https://www.carecredit.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'CenterVue',
					img: 'centervue.png',
					href: 'https://www.icare-world.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Centricity Vision',
					img: 'centricity.png',
					href: 'https://zeptozone.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Chester Country Eye Care',
					img: 'ccec.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Cristalens',
					img: 'cristalens.png',
					href: 'https://cristalens-international.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Doctor Retina',
					img: 'doctor-retina.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'DORC',
					img: 'dorc.png',
					href: 'https://dorcglobal.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Dr. Neuhann Ophthalmologikum',
					img: 'drneuhann-marienplatz.svg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Dr. Taneri',
					img: 'dr-taneri.svg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Euclid',
					img: 'euclid.png',
					href: 'https://euclidlenses.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Eye Centers of America',
					img: 'glassman-eye-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Eye Health America',
					img: 'eye-health-america.png',
					href: 'https://www.eyehealthamerica.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Eye Surgeons Associates',
					img: 'eye-surgeons-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'EyeCare 20/20',
					img: 'eyecare-20-20.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'EyeCare Partners',
					img: 'eyecare-partners.png',
					href: 'https://www.eyecare-partners.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'EyePoint Pharmaceuticals',
					img: 'eyepoint.png',
					href: 'https://eyepointpharma.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Flying L Partners',
					img: 'flying-l-partners.png',
					href: 'http://flyinglpartners.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Fuerst Eye Center',
					img: 'fuerst-eye-center.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Glaucoma Research Foundation',
					img: 'grf.png',
					href: 'https://glaucoma.org/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Glaukos',
					img: 'glaukos.png',
					href: 'https://www.glaukos.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Haag-Streit',
					img: 'haag-streit.png',
					href: 'https://www.haag-streit.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Hayden Vision',
					img: 'hayden-vision.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Hecker Eye Care Associates',
					img: 'hecker-eye-care-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Hoya Vision',
					img: 'hoya.png',
					href: 'https://www.hoyavision.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'IRIDEX',
					img: 'iridex.png',
					href: 'https://www.iridex.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'iSTAR Medical',
					img: 'istar.png',
					href: 'https://www.istar-medical.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Ivantis',
					img: 'ivantis.png',
					href: 'https://www.ivantisinc.uk/',
					contexts: ['combined', 'industry']
				},
				/*
				{
					title: 'Jensen Eye Associates',
					img: 'jensen-eye-associates.png',
					href: '#',
					contexts: ['medical']
				},
				*/
				{
					title: 'Johnson & Johnson Vision',
					img: 'jnj-vision.png',
					href: 'https://www.jjvision.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Kala',
					img: 'kala.png',
					href: 'https://www.kalarx.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Katzen Eye Group',
					img: 'katzen-eye-group.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Key-Whitman Eye Center',
					img: 'key-whitman-eye-center.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Leihigh Valley Center for Sight',
					img: 'center-for-sight.webp',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'LENSAR',
					img: 'lensar.png',
					href: 'https://lensar.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'LensGen',
					img: 'lensgen.png',
					href: 'https://lensgen.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Louisiana Eye Specialists',
					img: 'louisiana-eye-specialists.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Matossian Eye Associates',
					img: 'matossian-eye-associates.webp',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'MCLI',
					img: 'mcli.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Myra Vision',
					img: 'myra-vision.png',
					href: 'https://www.myravision.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Nevada Eye Physicians',
					img: 'nevada-eye-physicians.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'New World Medical',
					img: 'new-world-medical.png',
					href: 'https://www.newworldmedical.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'NIDEK',
					img: 'nidek.png',
					href: 'https://www.nidek-intl.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'North Bay Eye Associates',
					img: 'north-bay-eye-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'North Central Eye Associates',
					img: 'north-central-eye-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'North Fulton Eye Center',
					img: 'north-fulton-eye-center.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Novaliq',
					img: 'novaliq.png',
					href: 'https://www.novaliq.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Ocular Therapeutix',
					img: 'ocular-therapeutix.png',
					href: 'https://www.ocutx.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'OcuTerra',
					img: 'ocuterra.png',
					href: 'https://www.ocuterratx.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Oertli Instrumente AG',
					img: 'oertli.png',
					href: 'https://www.oertli-instruments.com/en',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Optos',
					img: 'optos.png',
					href: 'https://www.optos.com/',
					contexts: ['combined', 'industry']
				},
				/*
				{
					title: 'Physicians Eye Care & Laser Center',
					img: 'physicians-eye-care-and-laser-center.png',
					href: '#',
					contexts: ['medical']
				},
				*/
				{
					title: 'PhysIOL',
					img: 'physiol.png',
					href: 'https://www.bvimedical.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Rayner',
					img: 'rayner.png',
					href: 'https://rayner.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'ReFocus Eye Health',
					img: 'refocus-eye-health.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Remarkable MedTech GmbH',
					img: 'remarkable-medtech.png',
					href: 'https://www.remarkable-medtech.com/',
					contexts: ['medical']
				},
				{
					title: 'Santen',
					img: 'santen.png',
					href: 'https://www.santen.com/en',
					contexts: ['combined', 'industry']
				},
				{
					title: 'SCHWIND',
					img: 'schwind.png',
					href: 'https://www.eye-tech-solutions.com/en',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Scott & Christie Eyecare Associates',
					img: 'scott-and-christie-eyecare-associates.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Sight Sciences',
					img: 'sight-sciences.png',
					href: 'https://www.sightsciences.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Solomon Eye',
					img: 'solomon-eye.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Spindel Eye Associates',
					img: 'spindel-eye-associates.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'STAAR',
					img: 'staar.png',
					href: 'https://www.staar.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'TearClear',
					img: 'tearclear.png',
					href: 'https://tearclear.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'The Eye Center',
					img: 'the-eye-center.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'Topcon',
					img: 'topcon.png',
					href: 'https://topconhealthcare.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Vance Thompson Vision',
					img: 'vance-thompson-vision.png',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'ViaLase',
					img: 'vialase.png',
					href: 'https://www.vialase.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Westfield Eye Center',
					img: 'westfield-eye-center.jpg',
					href: '#',
					contexts: ['medical']
				},
				{
					title: 'ZEISS',
					img: 'zeiss.png',
					href: 'https://www.zeiss.com/',
					contexts: ['combined', 'industry']
				},
				{
					title: 'Ziemer Ophthalmic Systems AG',
					img: 'ziemer.png',
					href: 'https://www.ziemergroup.com/',
					contexts: ['combined', 'industry']
				}
			]
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		}),
		articles() {
			return this.$store.state.articles
		},
		products() {
			return this.$store.state.products.filter(product => product.landing_page_carousel_visible)
		},
		activeContext() {
			const path = this.$route.path.replace('/', '')
			if (path && ['medical', 'industry'].includes(path)) {
				return path
			}
			
			if (this.campaign) {
				return 'medical'
			}
			
			return 'combined'
		},
		activeContextSliderStyle() {
			if (this.activeContext === 'industry') {
				return {
					left: '0%',
					'border-top-left-radius': '18px',
					'border-bottom-left-radius': '18px'
				}
			} else if (this.activeContext === 'medical') {
				return {
					left: '50%',
					'border-top-right-radius': '18px',
					'border-bottom-right-radius': '18px'
				}
			}
			
			return '0%'
		},
		currentMastheadBgImg() {
			if (this.activeContext in this.mastheadBgImgs) {
				return this.mastheadBgImgs[this.activeContext]
			}
			
			return this.mastheadBgImgs.combined
		},
		customerSlides() {
			return this.customers.filter(customer => customer.contexts.includes(this.activeContext))
		},
		currentCustomerHeading() {
			return this.customerHeadings[this.activeContext]
		},
		styleAnimation() {
			return {
				animation: this.customerSlides.length*5 + 's slide infinite linear'
			}
		}
	},
	mounted() {
		if (this.activeContext === 'medical' && 'thank-you' in this.$route.query) {
			this.$scrollTo('#register')
		}
		
		$('.registration-containers').focusin(function (e) {
			$(e.target).parents('.col-lg-5').children('.card').fadeTo('fast', 1)
			$(e.target).parents('.col-lg-5').siblings('.col-lg-5').children('.card').fadeTo('fast', 0.5)
		})
		
		document.addEventListener('visibilitychange', this.visibilityChange)
	},
	beforeDestroy() {
		window.removeEventListener('visibilitychange', this.visibilityChange)
	},
	watch: {
		'activeContext': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				if (newVal === 'industry') {
					$('#main-corporate-container').parents('.card').fadeTo('fast', 1)
				} else if (newVal === 'medical') {
					$('#main-clinical-container').parents('.card').fadeTo('fast', 1)
				}
			}
		}
	},
	methods: {
		visibilityChange(e) {
			if (document.hidden) {
				this.carouselInterval = 0
			} else {
				this.carouselInterval = 3000
			}
		},
		contextSwitchIndustry() {
			this.$ga.event('landing context switcher button click', 'click', 'Industry Professional')
			
			if (this.activeContext !== 'industry') {
				this.$router.push('/industry')
			} else {
				this.$router.push('/')
			}
		},
		contextSwitchMedical() {
			this.$ga.event('landing context switcher button click', 'click', 'Medical Professional')
			
			if (this.activeContext !== 'medical') {
				this.$router.push('/medical')
			} else {
				this.$router.push('/')
			}
		},
		slidePrev() {
			if ((this.slide - 1) in this.products) {
				this.slide--
			} else {
				this.slide = this.products.length - 1
			}
		},
		slideNext() {
			if ((this.slide + 1) in this.products) {
				this.slide++
			} else {
				this.slide = 0
			}
		},
		showArticle(article) {
			this.$ga.event('article card click', 'click', article.title)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Articles', 'Opened Article Modal', article.title, article.id, {
				dimension1: article.publish_date
			}])
			
			this.$modal.show('articleModal' + article.id)
		},
		showShareArticleModal(article) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Articles', 'Opened Modal', '"Share Article Sample" Modal', article.id, {
				dimension1: article.publish_date
			}])
			
			this.$modal.show('shareArticleModal', { articleId: article.id })
		},
		jumpToRegister(article) {
			const _paq = window._paq = window._paq || []
			if (article.content) {
				this.$ga.event('article register button click', 'click', article.title)
				_paq.push(['trackEvent', 'Articles', 'Article Register Button Click', article.title, article.id, {
					dimension1: article.publish_date
				}])
			} else {
				this.$ga.event('locked article register button click', 'click', article.title)
				_paq.push(['trackEvent', 'Articles', 'Locked Article Register Button Click', article.title, article.id, {
					dimension1: article.publish_date
				}])
			}
			
			this.$modal.hide('articleModal' + article.id)
			this.$scrollTo('#register')
		}
	},
	components: {
		'no-ssr': NoSSR,
		BCard,
		BCardGroup,
		BCardBody,
		BCardImgLazy,
		BTabs,
		BTab,
		BCarousel,
		BCarouselSlide,
		BLink,
		VueGoodshareLinkedIn,
		VueGoodshareFacebook,
		VueGoodshareTwitter,
		Navbar,
		CorporateRegistration,
		ClinicalRegistration,
		CampaignClinicalRegistration,
		FooterSection,
		ShareArticle
	}
}
</script>

<style lang="scss">
section.latest {
	#productsCarousel {
		border-radius: inherit;
		
		.carousel-inner {
			border-radius: inherit;
			
			.carousel-item {
				border-radius: .7rem;
				
				img {
					border-radius: inherit;
				}
			}
		}
	}
	
	.carousel-controls .description {
		color: #fff;
		
		p,
		li {
			font-size: 1rem !important;
		}
	}
}

section.about {
	.tabs {
		.nav-tabs {
			border: none;
			margin: 0px;
			align-items: flex-end;
			
			h4 {
				color: #0a5384;
				margin-top: 20px;
			}
			
			> li > a {
				border: 0;
				margin-right: 0;
				
				&:not(.active):hover {
					border: 0;
					border-bottom: 4px solid #d1e9ba;
					background: rgba(black, 0.03);
				}
				
				&.active {
					border-bottom: 4px solid #8dc752;
				}
			}
		}
		
		.tab-content {
			margin-top: -2px;
			background-color: #fff;
			border: 0;
			border-top: 4px solid #eee;
			padding: 15px 0;
			box-shadow: -1px 10px 16px -3px rgba(0,0,0,0.1);
			
			.media h4 {
				color: #0a5384;
				overflow: hidden; // fixes text overflow on mobile?
			}
			
			ul {
				margin-top: 20px;
				padding-left: 10px;
				list-style-type: none;
				
				> li {
					color: #0a5384;
					margin: 10px 0;
				}
			}
		}
	}
}

section.customers {
	.nowrap {
		white-space: nowrap;
		display: inline-block;
	}
}

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		 transform: translateX(-100%);
	}
}
</style>

<style lang="scss" scoped>
header.masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	padding-top: 150px;
	padding-bottom: 100px;
	
	color: #fff;
	background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), v-bind('currentMastheadBgImg'), linear-gradient(to left, $theme-secondary 15%, $theme-tertiary 60%);
	background-repeat: no-repeat;
	background-position-x: 20%;
	@include background-cover;
	
	&:after {
		content: "";
		position: absolute;
		top: 93%;
		left: 0;
		height: 100%;
		width: 150%;
		background: #fff;
		transform: rotate(-5deg);
	}
	
	.header-content {
		max-width: 850px;
		margin-bottom: 100px;
		
		h1 {
			text-shadow: 0 1px 0 #555;
			font-size: 76px;
			font-family: MistralStd;
			letter-spacing: normal;
		}
		
		h2 {
			text-shadow: 0 1px 0 #555;
			
			> strong {
				font-weight: 600;
			}
		}
		
		.context-switch-button {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: 1fr;
			justify-content: space-around;
			will-change: transform;
			
			&:before {
				position: absolute;
				content: '';
				top: -2px;
				left: -2px;
				height: calc(100% + 8px);
				width: calc(100% + 8px);
				border-radius: 18px;
				z-index: -1;
				filter: blur(10px);
				background: linear-gradient(45deg, #fff, transparent, #fff);
				background-size: 400%;
				animation: animate 20s linear infinite;
			}
			&-case {
				display: inline-block;
				background-color: #fff;
				border: 1px solid #fff;
				height: 100%;
				color: #555;
				transition: .3s ease all;
				text-transform: uppercase;
				font-weight: bold;
				font-size: .75rem;
				letter-spacing: 3px;
				padding-bottom: 1px;
				
				&.left {
					border-top-left-radius: 18px;
					border-bottom-left-radius: 18px;
					border-right: 0.4pt solid rgba(0, 0, 0, 0.05);
				}
				&.right {
					border-top-right-radius: 18px;
					border-bottom-right-radius: 18px;
					border-left: 1px solid rgba(0, 0, 0, 0.05);
				}
				&:not(.active-case) {
					background-color: #fff;
				}
				&:disabled {
					border: none;
					background: rgba(0, 0, 0, 0.3);
					color: #fff;
				}
				&:hover:not(.active-case):not(:disabled) {
					background: rgba(255, 255, 255, 0.9);
					cursor: pointer;
				}
				&:focus {
					outline: none;
				}
			}
			
			.active {
				color: #151515;
				background-color: #28a745;
				position: absolute;
				left: 50%;
				top: 0;
				width: 50%;
				height: 100%;
				z-index: -1;
				transition: .3s ease-out all;
				
				&-case {
					color: #fff;
					background: none;
				}
			}
		}
	}
	@media (min-width: 992px) {
		height: 80vh;
		min-height: 600px;
		padding-top: 0;
		padding-bottom: 0;
		
		.header-content {
			margin-bottom: 0;
			text-align: left;
		}
	}
	@media (min-width: 768px) {
		.header-content .context-switch-button-case {
			font-size: 1rem;
		}
	}
}

section.customers {
	position: relative;
	padding-top: 50px;
	box-shadow: inset 0 -16px 16px -16px rgba(0, 0, 0, 0.1);
	
	.section-heading {
		color: #003263;
		font-size: 2rem;
	}
	
	.logos {
		overflow: hidden;
		background: #fff;
		white-space: nowrap;
		position: relative;
		
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0;
			width: 10%;
			height: 100%;
			z-index: 2;
		}
		&:before {
			left:0;
			background:linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
		}
		&:after {
			right: 0;
			background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
		}
		
		&:hover .logos-slide {
			animation-play-state: paused !important;
		}
		
		.logos-slide {
			display: inline-block;
			
			a[href="#"] {
				pointer-events: none;
			}
			
			img {
				max-width: 150px;
				margin: 0 40px;
			}
		}
	}
}

section.latest {
	position: relative;
	background: no-repeat center top url('~@assets/img/LatestMarketReports-BG-V2.jpg'), repeat-y center bottom url('~@assets/img/LatestMarketReports-BG-V2-bottom.jpg'), #f3fbfd;
	overflow-x: hidden;
	padding-top: 50px;
	
	.section-heading {
		h2 {
			color: #003263;
		}
		
		p {
			color: #777;
		}
	}
	
	.latest-content {
		position: relative;
		z-index: 1;
		padding-bottom: 1rem;
		
		.device-wrapper {
			margin: 0 auto;
			
			section {
				padding: 0;
			}
			
			.device {
				position: relative;
				background-size: cover;
				padding-bottom: 130.5%;
				
				.screen {
					position: absolute;
					background-size: cover;
					background-color: #fff;
					pointer-events: all;
					cursor: pointer;
					top: 3.28%;
					left: 4.2%;
					width: 91.7%;
					height: 93.6%;
					border-radius: .7rem;
				}
			}
		}
		
		h2 {
			font-size: 50px;
			max-width: 450px;
			margin-top: 0;
			margin-bottom: 25px;
			color: #fff;
		}
		
		@media (min-width: 768px) {
			h2 {
				font-size: 80px;
			}
		}
	}
	
	.carousel-container {
		.col-lg-3 {
			padding-top: 3rem;
			
			.media > div:first-child {
				border-radius: 50%;
				width: 70px;
				min-width: 70px;
				height: 70px;
				padding: 14px 0 0;
				border: 2px solid #fff;
				color: #fff;
				text-align: center;
				font-size: 1.6em;
			}
			.media .media-body > h5 {
				color: #fff;
				font-weight: 600;
			}
		}
		
		@media (max-width: 991.98px) {
			.col-lg-3 {
				padding-top: 0;
				
				&:last-child {
					margin-bottom: 30px;
				}
			}
			
			.col-lg-3:first-child {
				.media > div:first-child {
					color: #000;
					border-color: #000;
				}
				.media .media-body > h5 {
					color: #000;
				}
			}
		}
	}
	
	.carousel-controls {
		h3 {
			color: #fff;
			font-weight: 600;
			
			a {
				&:hover,
				&:focus,
				&:active,
				&.active {
					text-decoration: none;
				}
			}
		}
		
		a > span {
			font-weight: normal;
			line-height: 2;
			border-bottom: 1px solid #fff;
		}
		
		a > i {
			font-size: 2.5em;
		}
	}
}

@media (max-width: 500px) {
	section.latest {
		padding-top: 0;
	}
}

section.physicians {
	position: relative;
	background: no-repeat url('~@assets/img/physicians/physicians-front-bg.jpg'), $theme-primary;
	@include background-cover;
	margin-bottom: -60px;
	overflow-x: hidden;
	padding-top: 50px;
	
	.section-heading {
		color: #fff;
		
		h5 > strong {
			font-weight: 600;
		}
	}
	
	.physicians-content {
		color: #fff;
		
		h4 {
			font-weight: 600;
			font-size: 1.3rem;
			
			> i {
				color: #8dc752;
				margin-right: 8px;
				border-radius: 50%;
				background-color: #fff;
				width: 19px;
				height: 19px;
			}
		}
		
		p {
			font-size: inherit;
			line-height: 1.3;
		}
	}
	
	.physicians-footer {
		color: #fff;
		
		img {
			width: auto;
			max-height: 50px;
		}
	}
}

section.about {
	position: relative;
	padding-top: 50px;
	
	.section-heading {
		color: #003263;
	}
	
	p {
		color: $gray-light;
	}
}

.button-social * {
	box-sizing: border-box;
}

// Button Social link style
.button-social {
	display: inline-flex;
	cursor: pointer;
	padding: 7px 10px;
	margin: 3px 1.5px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

// Button Social link style on hover
.button-social:hover {
	opacity: 0.9;
}

a.button-social {
	color: #fff !important;
	
	[class^="icon-"]:before,
	[class*=" icon-"]:before {
		font-family: Fontello;
		font-style: normal;
		font-weight: normal;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: 0.2em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: 0.2em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	.icon-email:before {
		content: "\e80f";
	}
}

a.email__design__flat {
	background-color: rgb(65, 65, 65);
	color: rgb(254, 254, 254);
}

@keyframes animate {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
</style>