<template>
	<div>
		<navbar :subpage="true" :requestedProductId="requestedProductId" />
		<header class="reports-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>{{ pageTitle }}</span>
			</li>
		</ol>
		<section class="reports" id="reports">
			<div class="container header-row">
				<div>
					<div class="mx-auto">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-05.svg')" width="70" height="70" class="d-block mx-auto" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading text-center">{{ pageTitle }}</h1>
						<template v-if="tagPageDescription">
							<div v-html="tagPageDescription" class="overflow-hidden"></div>
						</template>
						<p v-else class="text-center">Market Scope uses a multi-source methodology when preparing our annual and quarterly reports. We incorporate surgeon surveys, our extensive database of physicians and surgery centers, disease models, clinical studies, financial reports, and more. Our wide-ranging knowledge of products and technology and our comprehensive network of industry contacts also contribute to our coverage on the ophthalmic industry.</p>
					</div>
				</div>
				<div>
					<transition-group name="list-complete" tag="div" class="mx-auto text-center tags">
						<button v-if="activeTag && tags.some(tag => tag.slug === activeTag && (tag.parent_tags.length || tag.child_tags.length))" :key="0" @click="filterByParentTag" type="button" class="btn btn-sm btn-level-up list-complete-item m-1">&nbsp;<i class="fas fa-level-up-alt"></i></button>
						<router-link v-for="tag in filteredTags" :key="tag.id" :to="{ params: { tag: activeTag !== tag.slug ? tag.slug : null }, query: Object.assign({}, $route.query, { page: 1 }) }" :class="{ btn: true, 'btn-primary': true, 'btn-sm': true, 'list-complete-item': true, 'm-1': true, active: activeTag === tag.slug }">{{ tag.label }}<i v-if="activeTag === tag.slug" class="fas fa-times-circle ml-1"></i><i v-if="loadingTag === tag.slug && activeTag !== tag.slug" class="fas fa-spinner fa-spin ml-1"></i></router-link>
					</transition-group>
				</div>
			</div>
			<div class="container" id="reports-container">
				<div v-if="pageCount > 0" class="mb-3">
					<b-form-select v-model="sortSelected" :options="sortOptions" class="pr-4 w-auto position-absolute"></b-form-select>
					<b-pagination-nav
						v-model="currentPage"
						:number-of-pages="pageCount"
						align="center"
						:linkGen="linkGen"
						@change="$scrollTo('#reports-container')"
						use-router
					/>
				</div>
				<div v-else>
					<div class="mx-auto text-center">
						<h4 class="my-4 text-muted">No results</h4>
					</div>
				</div>
				<div>
					<div v-for="product in products" @click="$router.push({ name: 'Report', params: { id: product.id, slug: product.slug }, hash: '#reports' })" class="card bg-light w-100 mb-4">
						<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug }, hash: '#reports' }" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%;" />
						<div v-if="product.pinned" class="ribbon-wrapper ribbon-lg">
							<div class="ribbon bg-warning">FEATURED</div>
						</div>
						<div class="card-body h-100">
							<div class="container">
								<div class="row">
									<div class="col-md-2 pb-3 report-sample">
										<template v-if="product.document_cover_filename || product.document_sample_page_images.length">
											<div :class="{ 'report-sample-img': true, unpublished: !product.published }">
												<!--
												<slider :ref="'slider' + product.id" :pages="product.document_sample_page_images" :sliderinit="sliderinit">
													<div slot="loading">loading...</div>
												</slider>
												-->
												<img v-if="product.document_cover_filename" class="img-fluid" v-lazy="'/files/products/covers/' + product.id + '/' + product.document_cover_filename" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
												<img v-else-if="product.document_sample_page_images.length" class="img-fluid" v-lazy="'/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
												<div v-if="!product.published" class="unpublished-content">
													<span :style="{ top: 'calc(50% - ' + (product.coming_soon_text ? '48' : '36') + 'px)' }"><i class="fa fa-clock"></i><br />COMING<br />SOON<template v-if="product.coming_soon_text"><br /><small>{{ product.coming_soon_text }}</small></template></span>
												</div>
											</div>
											<div class="mt-3">
												<!--
												<a href="#" @click.prevent="slideToToc(product.id)" class="btn btn-primary float-left" style="margin-right: 15px;">Table of Contents</a>
												<div class="float-left" style="margin-top: 3px;">
													<a href="#" @click.prevent="slidePre(product.id)" class=""><i class="fa fa-angle-left"></i></a>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<a href="#" @click.prevent="slideNext(product.id)" class=""><i class="fa fa-angle-right"></i></a>
												</div>
												-->
												<div>
													<a v-if="product.document_brochure_filename" @click.stop target="_blank" :href="'/files/products/brochures/' + product.id + '/' + product.document_brochure_filename" class="btn btn-sm btn-primary w-100 download-link">Brochure</a>
												</div>
												<div class="mt-1">
													<a v-if="product.product_skus.length" href="#" @click.prevent.stop="showRequestTocModal(product.id)" class="btn btn-sm btn-primary w-100">Request a Sample</a>
													<a v-if="product.product_type.plans.length" href="#" @click.prevent.stop="showRequestSampleModal(product.product_type.id)" class="btn btn-sm btn-primary w-100">Request a Sample</a>
												</div>
												<div class="mt-1">
													<button v-if="product.published" type="button" class="btn btn-sm btn-purchase w-100" @click.stop="requestProduct(product)" v-scroll-to="'#register'">{{ purchaseBtnText(product) }}</button>
													<button v-else type="button" class="btn btn-sm btn-purchase w-100" @click.stop="requestUnpublishedProduct(product)" v-scroll-to="'#register'">Notify Me</button>
												</div>
											</div>
										</template>
									</div>
									<div class="col-md-10 report-info">
										<div :class="{ 'float-right': true, 'text-right': true, 'mr-md-5': product.pinned }">
											<span v-for="tag in product.tags" class="badge badge-pill" :style="{ backgroundColor: tag.color }">
												<router-link v-if="tag.id" @click.native.stop :to="{ params: { tag: tag.slug }, query: { page: 1 } }">{{ tag.label }}</router-link>
											</span>
										</div>
										<h3 v-if="product.product_type.plans.length">{{ product.product_type.name }}</h3>
										<h3 class="mb-3"><strong>{{ product.attributes }}</strong></h3>
										<div class="description" v-html="product.description" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div @click="$router.push({ name: 'PrimaryResearch' })" class="card bg-light w-100 mb-4 recommended">
						<router-link :to="{ name: 'PrimaryResearch' }" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%;" />
						<div class="ribbon-wrapper ribbon-lg">
							<div class="ribbon">RECOMMENDED</div>
						</div>
						<div class="card-body h-100">
							<div class="container">
								<div class="row">
									<div class="col-md-2 pb-3 report-sample">
										<template>
											<div class="report-sample-img">
												<img class="img-fluid" v-lazy="require('@assets/img/product-primary-research-cover.jpg')" alt="Custom & Primary Research" />
											</div>
											<div class="mt-3">
												<div>
													<button type="button" class="btn btn-sm btn-primary w-100">View Primary Research Options</button>
												</div>
												<div class="mt-1">
													<button @click.stop="showContactUsModal" type="button" class="btn btn-sm btn-purchase w-100">Contact Us</button>
												</div>
											</div>
										</template>
									</div>
									<div class="col-md-10 report-info">
										<h3 class="mb-3"><strong>Not seeing what you need?</strong></h3>
										<div class="description">
											<p>In addition to our standard comprehensive reports, we also leverage our expert knowledge of the ophthalmic industry to design custom research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in defining problems and executing solutions.</p>
											<p>Common project types include custom data analysis, surveying and/or interviewing of ophthalmologists, focus groups, territory analyses, and other primary research initiatives -- but each project is custom designed to meet each specific clients' needs.</p>
											<p>Please contact us for an individualized project consultation.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="pageCount > 0">
					<b-pagination-nav
						v-model="currentPage"
						:number-of-pages="pageCount"
						align="center"
						:linkGen="linkGen"
						@change="$scrollTo('#reports-container')"
						use-router
					/>
				</div>
			</div>
		</section>
		<register :headerText="'Want to Purchase the Full Report?'" :requestedProductId="requestedProductId" />
		<footer-section />
		<request-sample />
		<request-toc />
		<contact-us />
	</div>
</template>

<script>
import { stripHtml } from 'string-strip-html'
import { BPaginationNav } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import RequestSample from './modals/RequestSample'
import RequestToc from './modals/RequestToc'
import ContactUs from './modals/ContactUs'

export default {
	name: 'Reports',
	metaInfo() {
		let path = this.$route.fullPath
		let hashIndex = path.indexOf('#')
		if (hashIndex !== -1) {
			path = path.substring(0, hashIndex)
		}
		
		/*
		if (this.pageCount === 0) {
			meta.push({
				name: 'robots',
				content: 'noindex',
				vmid: 'robots'
			})
		}
		*/
		
		return {
			title: this.pageTitle,
			meta: [
				{
					property: 'og:description',
					content: this.metaDescription,
					vmid: 'og:description'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/reports-front-bg.jpg',
					vmid: 'og:image'
				},
				{
					property: 'og:url',
					content: '//www.market-scope.com' + path,
					vmid: 'og:url'
				}
			],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com' + path,
					vmid: 'canonical'
				}
			]
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', vm.$metaInfo.title + ' | Market Scope'])
		})
	},
	beforeRouteUpdate(to, from, next) {
		next()
		
		const _paq = window._paq = window._paq || []
		if (this.prevRoute && this.prevRoute.fullPath) {
			_paq.push(['setReferrerUrl', window.location.origin + this.$router.resolve(this.prevRoute.fullPath).href])
		}
		_paq.push(['setCustomUrl', window.location.origin + this.$router.resolve(this.$route.fullPath).href])
		_paq.push(['trackPageView', this.$metaInfo.title + ' | Market Scope'])
	},
	asyncData({ store, route }) {
		const page = route.query.page && route.query.page.toString().match(/^[1-9]\d*$/) ? parseInt(route.query.page, 10) : 1
		
		return Promise.all([
			store.dispatch('fetchProductTags'),
			store.dispatch('fetchProducts', {
				limit: 10,
				tag: route.params.tag,
				page: page,
				sort_type: route.query.sort
			})
		])
	},
	data() {
		return {
			requestedProductId: null,
			currentPage: this.$route.query.page && this.$route.query.page.toString().match(/^[1-9]\d*$/) ? parseInt(this.$route.query.page, 10) : 1,
			sortSelected: this.$route.query.sort ? this.$route.query.sort : 'newest',
			sortOptions: [
				{ value: 'newest', text: 'Newest' },
				{ value: 'oldest', text: 'Oldest' },
				{ value: 'az', text: 'A-Z' },
				{ value: 'za', text: 'Z-A' }
			]
		}
	},
	computed: {
		tags() {
			return this.$store.state.productTags
		},
		activeTag() {
			if (this.$route.params.tag) {
				return this.$route.params.tag
			}
			
			return null
		},
		loadingTag() {
			return this.$store.state.productsTagLoading
		},
		filteredTags() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj && (tagObj.child_tags.length || tagObj.parent_tags.length)) {
					if (tagObj.child_tags.length) {
						return [tagObj].concat(tagObj.child_tags)
					} else {
						const parentTagObj = this.tags.find(tag => tag.id === tagObj.parent_tags[0].id)
						if (parentTagObj) {
							return parentTagObj.child_tags
						}
					}
				}
			}
			
			return this.tags.filter(tag => !tag.parent_tags.length)
		},
		products() {
			return this.$store.state.products
		},
		pageTitle() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagPageTitle = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_reports_page_title')
					if (tagPageTitle) {
						return stripHtml(tagPageTitle.content).result
					} else {
						return tagObj.label + ' Reports'
					}
				}
			}
			
			return 'Latest Reports'
		},
		tagPageDescription() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagPageDescription = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_reports_page_description')
					if (tagPageDescription) {
						return tagPageDescription.content
					}
				}
			}
			
			return null
		},
		metaDescription() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagMetaDescription = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_reports_meta_description')
					if (tagMetaDescription) {
						return stripHtml(tagMetaDescription.content).result
					}
				}
			}
			
			return ''
		},
		pageCount() {
			return this.$store.state.productPageCount
		}
	},
	watch: {
		'$route': function (to, from) {
			if (to.query.page !== from.query.page) {
				this.currentPage = to.query.page && to.query.page.toString().match(/^[1-9]\d*$/) ? parseInt(to.query.page, 10) : 1
			}
		},
		sortSelected: function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$router.push({ query: Object.assign({}, this.$route.query, { sort: newVal }) })
			}
		}
	},
	methods: {
		filterByParentTag() {
			if (this.activeTag) {
				this.currentPage = 1
				
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj && tagObj.parent_tags.length) {
					this.$router.push({ params: { tag: tagObj.parent_tags[0].slug }, query: Object.assign({}, this.$route.query, { page: 1 }) })
				} else {
					this.$router.push({ params: { tag: null }, query: Object.assign({}, this.$route.query, { page: 1 }) })
				}
			}
		},
		requestProduct(product) {
			this.$ga.event('report purchase button click', 'click', product.label)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Report Purchase Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		requestUnpublishedProduct(product) {
			this.$ga.event('unpublished report request button click', 'click', product.label)
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Unpublished Report Request Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		showContactUsModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Contact Us" Modal'])
			
			this.$modal.show('contactUsModal')
		},
		showRequestSampleModal(productTypeId) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Request a Sample" Modal'])
			
			this.$modal.show('requestSampleModal', { productTypeId: productTypeId })
		},
		showRequestTocModal(productId) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Request Sample & Table of Contents" Modal'])
			
			this.$modal.show('requestTocModal', { productId: productId })
		},
		linkGen(pageNum) {
			if (this.$route.query.sort) {
				return `?page=${pageNum}&sort=${this.$route.query.sort}`
			} else {
				return `?page=${pageNum}`
			}
		},
		purchaseBtnText(product) {
			if (product.product_type.plans.length || (product.product_skus.length && product.product_skus.some(productSku => productSku.price !== null))) {
				return 'Purchase'
			}
			
			return 'Download'
		}
	},
	components: {
		BPaginationNav,
		BFormSelect,
		Navbar,
		Register,
		FooterSection,
		RequestSample,
		RequestToc,
		ContactUs
	}
}
</script>

<style lang="scss">
.list-complete-item {
	transition: all .5s;
	display: inline-block;
}
.list-complete-enter,
.list-complete-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
.list-complete-leave-active {
	position: absolute;
}

section.reports {
	p,
	li {
		color: $gray-light;
	}
}

.reports .pagination {
	margin-bottom: 0;
	
	.page-link {
		color: #2fbbcc;
	}
	
	.page-item.active .page-link {
		background-color: #2fbbcc;
		border-color: #2fbbcc;
		color: #fff;
	}
	
	.page-item.disabled .page-link {
		color: #6c757d;
	}
}

.report-info .description p {
	font-size: 14px;
}
</style>

<style lang="scss" scoped>
// RIBBON
// --------------------------------------------------------
$ribbon-border-size: 3px !default;
$ribbon-line-height: 100% !default;
$ribbon-padding: .375rem 0 !default;
$ribbon-font-size: .8rem !default;
$ribbon-width: 90px !default;
$ribbon-wrapper-size: 70px !default;
$ribbon-top: 10px !default;
$ribbon-right: -2px !default;
$ribbon-lg-wrapper-size: 120px !default;
$ribbon-lg-width: 160px !default;
$ribbon-lg-top: 26px !default;
$ribbon-lg-right: 0px !default;
$ribbon-xl-wrapper-size: 180px !default;
$ribbon-xl-width: 240px !default;
$ribbon-xl-top: 47px !default;
$ribbon-xl-right: 4px !default;

.breadcrumb-item {
	text-transform: capitalize;
}

header.reports-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: 40% 60% url('~@assets/img/reports-front-bg.jpg'), $theme-secondary;
	background: 40% 60% url('~@assets/img/reports-front-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.reports {
	background: none;
	
	.section-heading {
		color: #0a5384;
		margin-bottom: 8px;
		text-transform: capitalize;
	}
	
	.header-row {
		margin-bottom: 25px;
		
		.tags > .btn {
			text-transform: none;
			
			&.btn-level-up {
				color: #6c757d;
				background-color: #fff;
				border-color: #dee2e6;
			}
		}
	}
	
	.card {
		border-color: rgba(0, 0, 0, 0.05);
		
		&:hover {
			cursor: pointer;
			border-color: #0a5384;
			background-color: transparent !important;
		}
		
		.card-body {
			padding-bottom: 0;
		}
		
		// Ribbon
		.ribbon-wrapper {
			height: $ribbon-wrapper-size;
			overflow: hidden;
			position: absolute;
			right: -2px;
			top: -2px;
			width: $ribbon-wrapper-size;
			z-index: 10;
			
			&.ribbon-lg {
				height: $ribbon-lg-wrapper-size;
				width: $ribbon-lg-wrapper-size;
				
				.ribbon {
					right: $ribbon-lg-right;
					top: $ribbon-lg-top;
					width: $ribbon-lg-width;
				}
			}
			
			&.ribbon-xl {
				height: $ribbon-xl-wrapper-size;
				width: $ribbon-xl-wrapper-size;
				
				.ribbon {
					right: $ribbon-xl-right;
					top: $ribbon-xl-top;
					width: $ribbon-xl-width;
				}
			}
			
			.ribbon {
				box-shadow: 0 0 $ribbon-border-size rgba(#111, .3);
				font-size: $ribbon-font-size;
				line-height: $ribbon-line-height;
				padding: $ribbon-padding;
				position: relative;
				right: $ribbon-right;
				text-align: center;
				text-shadow: 0 -1px 0 rgba(#111, .4);
				text-transform: uppercase;
				top: $ribbon-top;
				transform: rotate(45deg);
				width: $ribbon-width;
				
				&::before,
				&::after {
					border-left: $ribbon-border-size solid transparent;
					border-right: $ribbon-border-size solid transparent;
					border-top: $ribbon-border-size solid #9e9e9e;
					bottom: -$ribbon-border-size;
					content: '';
					position: absolute;
				}
				
				&::before {
					left: 0;
				}
				
				&::after {
					right: 0;
				}
			}
		}
	}
	
	.card.recommended {
		border: 1px solid #2fbbcc;
		
		.ribbon {
			font-size: 0.75rem;
			color: #fff;
			text-shadow: none;
			background: #2fbbcc;
		}
	}
	
	.report-sample {
		.report-sample-img {
			box-shadow: -5px 5px 30px 0px rgba(0, 0, 0, 0.3);
			
			/*
			.slider-pagination-bullet-active {
				background: #000 none repeat scroll 0 0 !important;
			}
			*/
		}
		
		.unpublished {
			position: relative;
			
			.unpublished-content {
				position: absolute;
				background: rgba(0, 0, 0, .6);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				
				span {
					position: absolute;
					width: 100%;
					text-align: center;
					color: #fff;
					font-size: 16px;
					
					.fa {
						color: #fff;
						font-size: 16px;
					}
					
					small {
						font-size: 14px;
					}
				}
			}
		}
		
		.btn {
			white-space: normal;
			letter-spacing: normal;
			text-transform: none;
			font-size: .8em;
			line-height: 1.2;
		}
		.btn-purchase {
			color: #fff;
			background-color: #e3a220;
			
			&:hover {
				color: #eee;
			}
		}
		
		.fa {
			color: #2fbbcc;
			font-size: 32px;
		}
	}
	
	.report-info {
		> h3 {
			color: #0a5384;
			word-wrap: normal;
		}
		
		span.badge-pill {
			color: #fff;
			margin-left: 3px;
			margin-bottom: 3px;
			
			> a {
				text-decoration: none;
				
				&:hover {
					color: #eee;
				}
			}
		}
		
		.description {
			color: $gray-light;
		}
	}
}
</style>