<template>
	<div>
		<navbar :subpage="true" />
		<header class="news-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>{{ articlesSectionHeader }} News</span>
			</li>
		</ol>
		<section class="news" id="news">
			<div class="container header-row">
				<div>
					<div class="mx-auto">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-08.svg')" width="70" height="70" class="d-block mx-auto" style="max-width: 70px; width: auto; height: auto;" />
						<h1 v-if="$route.name === 'News'" class="section-heading text-center">{{ pageTitle }}</h1>
						<h2 v-else class="section-heading text-center">{{ pageTitle }}</h2>
						<template v-if="tagPageDescription">
							<div v-html="tagPageDescription" class="overflow-hidden"></div>
						</template>
						<p v-else class="text-center">Market Scope provides comprehensive coverage of the ophthalmic market. Our news stories are a combination of public and proprietary content ranging from coverage of international meetings, surgeon survey results and trends, analysis of company financial reports and public medical registries, press releases, and interviews with executives. Register today to receive free weekly email updates, our top data chart, surgeon poll results, and much more. Locked content can be accessed through a subscription to our <router-link :to="{ name: 'Reports', params: { tag: 'newsletter' }, query: { page: 1 } }">ophthalmic</router-link> industry newsletters.</p>
					</div>
				</div>
				<div>
					<transition-group name="list-complete" tag="div" class="mx-auto text-center tags">
						<button v-if="activeTag && tags.some(tag => tag.slug === activeTag && (tag.parent_tags.length || tag.child_tags.length))" :key="0" @click="filterByParentTag" type="button" class="btn btn-sm btn-level-up list-complete-item m-1">&nbsp;<i class="fas fa-level-up-alt"></i></button>
						<router-link v-for="tag in filteredTags" :key="tag.id" :to="{ params: { tag: activeTag !== tag.slug ? tag.slug : null } }" :class="{ btn: true, 'btn-primary': true, 'btn-sm': true, 'list-complete-item': true, 'm-1': true, active: activeTag === tag.slug }">{{ tag.label }}<i v-if="activeTag === tag.slug" class="fas fa-times-circle ml-1"></i><i v-if="loadingTag === tag.slug && activeTag !== tag.slug" class="fas fa-spinner fa-spin ml-1"></i></router-link>
					</transition-group>
				</div>
			</div>
			<div class="recent">
				<div class="container">
					<div class="mb-3">
						<div class="mx-auto text-center">
							<h3 class="section-heading">{{ articlesSectionHeader }} News</h3>
						</div>
					</div>
					<div v-if="articles.length" class="row">
						<div v-for="article in recentArticles" class="col-12 col-md-6 col-lg-3">
							<div class="card">
								<div class="card-header">
									{{ new Date(article.publish_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }}
									<div class="float-right text-right">
										<template v-for="tag in article.landing_tags">
											<span v-if="tag.id" class="badge badge-pill" :style="{ backgroundColor: tag.color }">{{ tag.label }}</span>
										</template>
									</div>
								</div>
								<div class="card-body">
									<h5 class="card-title">{{ article.title }}</h5>
									<img v-if="article.preview_img_url" class="img-thumbnail d-block mx-auto" v-lazy="article.preview_img_url" :alt="(article.title).trim()" />
									<p class="card-text" v-html="article.content_preview"></p>
								</div>
								<div class="card-footer text-center">
									<router-link v-if="article.content" :to="{ name: 'Article', params: { id: article.id, slug: article.slug }, query: { publish_date: article.publish_date } }" class="stretched-link">Read Article</router-link>
									<router-link v-else :to="{ name: 'Article', params: { id: article.id, slug: article.slug }, query: { publish_date: article.publish_date } }" class="stretched-link"><i class="fas fa-lock"></i> Read Article</router-link>
								</div>
							</div>
						</div>
					</div>
					<div v-else class="row">
						<div class="mx-auto">
							<h4 class="mb-0 mt-2 text-muted">No results</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="past">
				<div class="container">
					<template v-if="pastArticles.length">
						<div class="mb-3">
							<div class="mx-auto text-center">
								<h3>Past News Stories</h3>
							</div>
						</div>
						<div class="row">
							<div v-for="article in pastArticles" class="col-12 col-md-6 col-lg-3">
								<div class="card">
									<div class="card-header">
										{{ new Date(article.publish_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }}
										<div class="float-right text-right">
											<template v-for="tag in article.landing_tags">
												<span v-if="tag.id" class="badge badge-pill" :style="{ backgroundColor: tag.color }">{{ tag.label }}</span>
											</template>
										</div>
									</div>
									<div class="card-body">
										<h5 class="card-title">{{ article.title }}</h5>
										<img v-if="article.preview_img_url" class="img-thumbnail d-block mx-auto" v-lazy="article.preview_img_url" :alt="(article.title).trim()" />
										<p class="card-text" v-html="article.content_preview"></p>
									</div>
									<div class="card-footer text-center">
										<router-link v-if="article.content" :to="{ name: 'Article', params: { id: article.id, slug: article.slug }, query: { publish_date: article.publish_date } }" class="stretched-link">Read Article</router-link>
										<router-link v-else :to="{ name: 'Article', params: { id: article.id, slug: article.slug }, query: { publish_date: article.publish_date } }" class="stretched-link"><i class="fas fa-lock"></i> Read Article</router-link>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="text-center mt-3 mb-5">
						<router-link to="/pages/news-archive" class="btn btn-primary">More News</router-link>
					</div>
				</div>
			</div>
		</section>
		<register :headerText="'Want to Read the Full Article?'" />
		<footer-section />
	</div>
</template>

<script>
import { stripHtml } from 'string-strip-html'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'

export default {
	name: 'News',
	metaInfo() {
		if (this.$route.name === 'News') {
			let path = this.$route.fullPath
			let hashIndex = path.indexOf('#')
			if (hashIndex !== -1) {
				path = path.substring(0, hashIndex)
			}
			
			let meta = [
				{
					property: 'og:description',
					content: this.metaDescription,
					vmid: 'og:description'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/news-front-bg.jpg',
					vmid: 'og:image'
				},
				{
					property: 'og:url',
					content: '//www.market-scope.com' + path,
					vmid: 'og:url'
				}
			]
			
			if (this.$route.params.tag) {
				let tag = this.$route.params.tag.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
				})
				
				meta.push({
					property: 'og:title',
					content: `Latest ${tag} Industry News & Trends`,
					template: content => `${content} | Market Scope`,
					vmid: 'og:title'
				})
			} else {
				meta.push({
					property: 'og:title',
					content: 'Latest Industry News & Trends',
					template: content => `${content} | Market Scope`,
					vmid: 'og:title'
				})
			}
			
			return {
				title: this.pageTitle,
				meta: meta,
				link: [
					{
						rel: 'canonical',
						href: 'https://www.market-scope.com' + path,
						vmid: 'canonical'
					}
				]
			}
		} else {
			return {}
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', vm.$metaInfo.title + ' | Market Scope'])
		})
	},
	beforeRouteUpdate(to, from, next) {
		next()
		
		const _paq = window._paq = window._paq || []
		if (this.prevRoute && this.prevRoute.fullPath) {
			_paq.push(['setReferrerUrl', window.location.origin + this.$router.resolve(this.prevRoute.fullPath).href])
		}
		_paq.push(['setCustomUrl', window.location.origin + this.$router.resolve(this.$route.fullPath).href])
		_paq.push(['trackPageView', this.$metaInfo.title + ' | Market Scope'])
	},
	asyncData({ store, route }) {
		return Promise.all([
			store.dispatch('fetchArticleTags'),
			store.dispatch('fetchArticles', {
				limit: !route.params.tag ? 24 : 200,
				tag: route.params.tag
			})
		])
	},
	computed: {
		tags() {
			return this.$store.state.articleTags
		},
		activeTag() {
			if (this.$route.params.tag) {
				return this.$route.params.tag
			}
			
			return null
		},
		loadingTag() {
			return this.$store.state.articlesTagLoading
		},
		filteredTags() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj && (tagObj.child_tags.length || tagObj.parent_tags.length)) {
					if (tagObj.child_tags.length) {
						return [tagObj].concat(tagObj.child_tags)
					} else {
						const parentTagObj = this.tags.find(tag => tag.id === tagObj.parent_tags[0].id)
						if (parentTagObj) {
							return parentTagObj.child_tags
						}
					}
				}
			}
			
			return this.tags.filter(tag => !tag.parent_tags.length)
		},
		articles() {
			return this.$store.state.articles
		},
		recentArticles() {
			if (!this.$route.params.tag) {
				return this.articles.length ? this.articles.slice(0, 12) : []
			} else {
				return this.articles
			}
		},
		pastArticles() {
			if (!this.$route.params.tag) {
				return this.articles.length ? this.articles.slice(12, 24) : []
			} else {
				return []
			}
		},
		pageTitle() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagPageTitle = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_news_page_title')
					if (tagPageTitle) {
						return stripHtml(tagPageTitle.content).result
					}
				}
			}
			
			return 'Latest Industry News & Trends'
		},
		tagPageDescription() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagPageDescription = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_news_page_description')
					if (tagPageDescription) {
						return tagPageDescription.content
					}
				}
			}
			
			return null
		},
		metaDescription() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					const tagMetaDescription = tagObj.tag_attributes.find(tagAttribute => tagAttribute.attr === 'landing_news_meta_description')
					if (tagMetaDescription) {
						return stripHtml(tagMetaDescription.content).result
					}
				}
			}
			
			return 'Stay current on the latest news and developments that are shaping the ophthalmic market.'
		},
		articlesSectionHeader() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj) {
					return tagObj.label
				}
			}
			
			return 'Recently Added'
		}
	},
	methods: {
		filterByParentTag() {
			if (this.activeTag) {
				const tagObj = this.tags.find(tag => tag.slug === this.activeTag)
				if (tagObj && tagObj.parent_tags.length) {
					this.$router.push({ params: { tag: tagObj.parent_tags[0].slug } })
				} else {
					this.$router.push({ params: { tag: null } })
				}
			}
		}
	},
	components: {
		Navbar,
		Register,
		FooterSection
	}
}
</script>

<style lang="scss">
.list-complete-item {
	transition: all .5s;
	display: inline-block;
}
.list-complete-enter,
.list-complete-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
.list-complete-leave-active {
	position: absolute;
}
</style>

<style lang="scss" scoped>
header.news-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: 40% 100% url('~@assets/img/news-front-bg.jpg'), $theme-secondary;
	background: 40% 100% url('~@assets/img/news-front-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

section.news {
	background-color: #fff;
	padding: 0;
	
	.recent {
		background-color: #f4f5f7;
		box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.1);
		padding-top: 30px;
		padding-bottom: 30px;
		
		h3.section-heading {
			text-transform: capitalize;
		}
	}
	
	.past {
		background-color: #fefefe;
		padding-top: 30px;
		
		.card {
			box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
		}
	}
	
	.header-row {
		background-color: #fefeff;
		padding-top: 30px;
		padding-bottom: 30px;
		
		p a {
			color: #2fbbcc;
		}
		
		.tags > .btn {
			text-transform: none;
			
			&.btn-level-up {
				color: #6c757d;
				background-color: #fff;
				border-color: #dee2e6;
			}
		}
	}
	
	.card {
		box-shadow: -1px 10px 16px -3px rgba(0, 0, 0, 0.1);
	}
}
</style>