<template>
	<div :class="{ media: true, 'p-3': true, 'locked': !source.content }">
		<router-link :to="{ name: 'Article', params: { id: source.id, slug: source.slug, offset: $parent.$parent.getOffset() }, query: { publish_date: source.publish_date } }" />
		<img v-if="source.preview_img_url" :src="source.preview_img_url" class="mr-3 img-thumbnail" :alt="(source.title).trim()" />
		<div class="media-body">
			<h5 class="mt-0 mb-1">{{ source.title }}</h5>
			<p class="card-text" v-html="source.content_preview"></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NewsArchiveArticle',
	props: {
		source: {
			type: Object,
	        default: () => ({})
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid #ddd;
	height: 100px;
	
	a {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: 1000;
	}
	
	.img-thumbnail {
		max-height: 70px;
	}
	
	.media-body {
		h5 {
			color: #0a5384;
		}
		
		p {
			font-size: smaller;
		}
	}
	
	&:hover {
		cursor: pointer;
		background-color: #fff;
		
		&:after {
			background: linear-gradient(0deg, #fff, transparent);
		}
	}
	
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		height: 3em;
		width: 100%;
		background: linear-gradient(0deg, rgba(244, 245, 247, 1), transparent);
	}
}

.locked:hover {
	background-color: inherit;
	
	&:after {
		content: "\F023";
		font-family: "Font Awesome 5 Free";
		text-decoration: inherit;
		position: absolute;
		font-size: 65px;
		color: #767676;
		top: 0;
		left: 0;
		margin-left: calc(-50% - 18px);
		padding-left: 100%;
		padding-right: 100%;
		z-index: 1;
		font-weight: 900;
	}
}
</style>